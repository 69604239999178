import { Epic } from "redux-observable"
import { Observable, interval } from "rxjs"
import { switchMap, mergeMap, filter } from "rxjs/operators"
import { produce, Draft } from "immer"
import { IState, RootAction } from ".."
import {
  createAction,
  createAsyncAction,
  getType,
  isActionOf,
} from "typesafe-actions"
import {
  NotificationsAttribute,
  TriggersAttribute,
  TriggerPeriodsAttribute,
  TopicsAttribute,
  SubscribersAttribute,
} from "./@types"
import { Dependencies } from "../../ReduxProvider"
import { tenantActionsAsync } from "../tenantReducer"
import { dataV2ActionsAsync } from "../dataReducerV2"
import { MetricValues } from "../dataReducerV2/@types"
import { formatTriggerPeriods } from "../dataReducerV2/functions/formatTriggerPeriods"
import { v4 } from "uuid"


const ROUND_INTERVAL = 5000 // 5 seconds
const ALERTS_DAYS = 1 // 1 day

/**
 * ==============================================================
 * STATE
 * ==============================================================
 */

export type Notifications = { [notificationId: string]: NotificationsAttribute }
export type Triggers = { [triggerId: string]: TriggersAttribute }
export type Topics = { [topicId: string]: TopicsAttribute }
export type Subscribers = { [subscriberId: string]: SubscribersAttribute }
export type TriggerPeriods = {
  [triggerId: string]: {
    [start: string]: TriggerPeriodsAttribute
  }
}

export interface NotificationsState {
  notifications: Notifications
  recentNotifications: Notifications //Used for graphs
  pastNotifications: Notifications //Used for graphs
  toastAlerts: Notifications // Used for alert toasts
  lastFetchedAlerts: Date
  hasMore: boolean
  triggers: Triggers
  topics: Topics
  subscribers: Subscribers
  triggerPeriods: MetricValues<TriggerPeriods>
  pastTriggerPeriods: MetricValues<TriggerPeriods>
  selectedTriggerId: string
  triggerActionOpen: boolean
  triggerDeleteOpen: boolean
}

export const initialNotificationsState: NotificationsState = {
  notifications: {},
  toastAlerts: {},
  lastFetchedAlerts: new Date(),
  recentNotifications: {},
  pastNotifications: {},
  hasMore: true,
  triggers: {},
  triggerPeriods: {
    d: {},
    m: {},
    f: {},
    s: {},
    c: {},
    h: {},
  },
  topics: {},
  subscribers: {},
  pastTriggerPeriods: {
    d: {},
    m: {},
    f: {},
    s: {},
    c: {},
    h: {},
  },
  selectedTriggerId: "",
  triggerActionOpen: false,
  triggerDeleteOpen: false,
}

/**
 * ==============================================================
 * ACTIONS
 * ==============================================================
 */
export const notificationActions = {
  setVariable: createAction(
    "@notifications/setVariable",
    (
      key: keyof NotificationsState,
      value: typeof initialNotificationsState[keyof NotificationsState]
    ) => ({
      key,
      value,
    })
  )(),
  addNotification: createAction(
    "@notifications/addNotification",
    (notification: NotificationsAttribute) => notification
  )(),
  updateNotifications: createAction(
    "@notifications/updateNotifications",
    (
      notificationIds: string[],
      body: {
        read?: boolean
        readBy?: string
        dismissed?: boolean
        dismissedBy?: string
        showToast?: boolean
      },
    ) => ({
      notificationIds,
      body
    })
  )(),
  addAlert: createAction(
    "@notifications/addAlert",
    (alert: NotificationsAttribute) => alert
  )(),
  setNotifications: createAction(
    "@notifications/setNotifications",
    (notifications: Notifications) => notifications
  )(),
  setTriggers: createAction(
    "@notifications/setTriggers",
    (triggers: Triggers) => triggers
  )(),
  setHasMore: createAction(
    "@notifications/setHasMore",
    (hasMore: boolean) => hasMore
  )(),
  setSelectedTriggerId: createAction(
    "@notifications/setSelectedTriggerId",
    (triggerId: string) => triggerId
  )(),
}
export const notificationActionsAsync = {
  fetchNotifications: createAsyncAction(
    "@notifications/fetchNotifications/req",
    "@notifications/fetchNotifications/res",
    "@notifications/fetchNotifications/err"
  )<
    {
      offset?: number
    },
    {
      notifications: Notifications
      hasMore: boolean
    },
    { error: Error }
  >(),
  fetchNotificationsByTime: createAsyncAction(
    "@notifications/fetchNotificationsByTime/req",
    "@notifications/fetchNotificationsByTime/res",
    "@notifications/fetchNotificationsByTime/err"
  )<
    {
      to: Date
      from: Date
    },
    {
      notifications: Notifications
    },
    { error: Error }
  >(),
  refreshAlerts: createAsyncAction(
    "@notifications/refreshAlerts/req",
    "@notifications/refreshAlerts/res",
    "@notifications/refreshAlerts/err"
  )<
    {},
    {
      notifications: Notifications,
      fetchedTime: Date
    },
    { error: Error }
  >(),
  showNotification: createAsyncAction(
    "@notifications/showNotification/req",
    "@notifications/showNotification/res",
    "@notifications/showNotification/err"
  )<
    {
      notification: NotificationsAttribute
    },
    {
      notification: NotificationsAttribute
    },
    { error: Error }
  >(),
  addNote: createAsyncAction(
    "@notifications/addNote/req",
    "@notifications/addNote/res",
    "@notifications/addNote/err"
  )<
    {
      message: string
    },
    {
      // notification: NotificationsAttribute
    },
    { error: Error }
  >(),
  createNotification: createAsyncAction(
    "@notifications/createNotification/req",
    "@notifications/createNotification/res",
    "@notifications/createNotification/err"
  )<
    {
      message: string
    },
    {
      notification: NotificationsAttribute
    },
    { error: Error }
  >(),
  editNotificationsState: createAsyncAction(
    "@notifications/editNotificationsState/req",
    "@notifications/editNotificationsState/res",
    "@notifications/editNotificationsState/err"
  )<
    {
      notificationIds: string[]
      body: {
        read?: boolean
        readBy?: string
        dismissed?: boolean
        dismissedBy?: string
        showToast?: boolean
      }
    },
    {
      notifications: Notifications
    },
    { error: Error }
  >(),
  fetchTriggers: createAsyncAction(
    "@notifications/fetchTriggers/req",
    "@notifications/fetchTriggers/res",
    "@notifications/fetchTriggers/err"
  )<
    {},
    {
      triggers: Triggers
    },
    { error: Error }
  >(),
  createTrigger: createAsyncAction(
    "@notifications/createTrigger/req",
    "@notifications/createTrigger/res",
    "@notifications/createTrigger/err"
  )<
    {
      trigger: TriggersAttribute
    },
    {
      trigger: TriggersAttribute
    },
    { error: Error }
  >(),
  updateTrigger: createAsyncAction(
    "@notifications/updateTrigger/req",
    "@notifications/updateTrigger/res",
    "@notifications/updateTrigger/err"
  )<
    {
      triggerId: string
      trigger: Partial<TriggersAttribute>
    },
    {
      trigger: TriggersAttribute
    },
    { error: Error }
  >(),
  deleteTrigger: createAsyncAction(
    "@notifications/deleteTrigger/req",
    "@notifications/deleteTrigger/res",
    "@notifications/deleteTrigger/err"
  )<
    {
      triggerId: string
    },
    {
      triggerId: string
    },
    { error: Error }
  >(),
  fetchTriggerPeriods: createAsyncAction(
    "@notifications/fetchTriggerPeriods/req",
    "@notifications/fetchTriggerPeriods/res",
    "@notifications/fetchTriggerPeriods/err"
  )<
    {
      start: Date
      end: Date
      past?: boolean
    },
    {
      past?: boolean
      triggerPeriods: MetricValues<TriggerPeriods>
    },
    { error: Error }
  >(),
  fetchTopics: createAsyncAction(
    "@notifications/fetchTopics/req",
    "@notifications/fetchTopics/res",
    "@notifications/fetchTopics/err"
  )<
    {},
    {
      topics: Topics
    },
    { error: Error }
  >(),
  fetchSubscribers: createAsyncAction(
    "@notifications/fetchSubscribers/req",
    "@notifications/fetchSubscribers/res",
    "@notifications/fetchSubscribers/err"
  )<
    {
      topicId: string
    },
    {
      subscribers: Subscribers
    },
    { error: Error }
  >(),
  updateTopic: createAsyncAction(
    "@notifications/updateTopic/req",
    "@notifications/updateTopic/res",
    "@notifications/updateTopic/err"
  )<
    {
      topicId: string
      topic: Partial<TopicsAttribute>
    },
    {
      topic: TopicsAttribute
    },
    { error: Error }
  >(),
  createTopic: createAsyncAction(
    "@notifications/createTopic/req",
    "@notifications/createTopic/res",
    "@notifications/createTopic/err"
  )<
    {
      name: string
      triggerId: string
    },
    {
      topic: TopicsAttribute
    },
    { error: Error }
  >(),
  deleteTopic: createAsyncAction(
    "@notifications/deleteTopic/req",
    "@notifications/deleteTopic/res",
    "@notifications/deleteTopic/err"
  )<
    {
      topicId: string
    },
    {
      topicId: string
    },
    { error: Error }
  >(),
  unsubscribe: createAsyncAction(
    "@notifications/unsubscribe/req",
    "@notifications/unsubscribe/res",
    "@notifications/unsubscribe/err"
  )<
    {
      subscriberId: string
    },
    {
      subscriberId: string
    },
    { error: Error }
  >(),
  subscribe: createAsyncAction(
    "@notifications/subscribe/req",
    "@notifications/subscribe/res",
    "@notifications/subscribe/err"
  )<
    {
      topicId: string,
      protocol: string,
      endpoint: string,
      name: string
    },
    {
      subscriber: SubscribersAttribute
    },
    { error: Error }
  >(),
  confirmSubscription: createAsyncAction(
    "@notifications/confirmSubscription/req",
    "@notifications/confirmSubscription/res",
    "@notifications/confirmSubscription/err"
  )<
    {
      topicId: string,
      name: string,
      verificationCode: string,
      endpoint: string
    },
    {
      subscribers: Subscribers
    },
    { error: Error }
  >(),
  createVmsEvent: createAsyncAction(
    "@notifications/createVmsEvent/req",
    "@notifications/createVmsEvent/res",
    "@notifications/createVmsEvent/err"
  )<
    {
      caption?: string,
      description?: string
    },
    {},
    { error: Error }
  >(),
}

type ValueOf<T> = T[keyof T]
export type NotificationsAction =
  | ReturnType<ValueOf<typeof notificationActions>>
  | ReturnType<ValueOf<ValueOf<typeof notificationActionsAsync>>>

/**
 * ==============================================================
 * REDUCERS
 * ==============================================================
 */

/**
 * Notifications
 */
export const notificationsReducer = produce(
  (draft: Draft<NotificationsState>, action: RootAction) => {
    switch (action.type) {
      case getType(tenantActionsAsync.selectProject.success):
        {
          draft.notifications = {} //Clear notifications on project change
          draft.recentNotifications = {} //Clear notifications on project change
          draft.pastNotifications = {} //Clear notifications on project change
          draft.triggers = {} //Clear on project change
          draft.hasMore = true //Reset
        }
        return
      case getType(notificationActions.setVariable):
        {
          const { key, value } = action.payload
          draft[key as any] = value as any
        }
        return
      case getType(notificationActions.addNotification):
        {
          const n = action.payload
          if (!n.notificationId) return //In case payload has an error
          draft.notifications[n.notificationId] = n
          draft.recentNotifications[n.notificationId] = n
          draft.pastNotifications[n.notificationId] = n
        }
        return
      case getType(notificationActions.updateNotifications):
        {
          const { notificationIds, body } = action.payload
          notificationIds.forEach(notificationId => {
            if (!draft.notifications[notificationId]) return;
            draft.notifications[notificationId] = {
              ...draft.notifications[notificationId],
              ...body
            };
          });
        }
        return
      case getType(notificationActions.addAlert):
        {
          const n = action.payload
          if (!n.notificationId || !n.alertRuleId) return
          // Toast for alerts
          // if (n.showToast) draft.toastAlerts[n.notificationId] = n
          // else draft.notifications[n.notificationId] = n
          draft.notifications[n.notificationId] = n
          // For charts
          draft.recentNotifications[n.notificationId] = n
          draft.pastNotifications[n.notificationId] = n
        }
        return
      case getType(notificationActions.setNotifications):
        {
          draft.notifications = action.payload
        }
        return
      case getType(notificationActions.setTriggers):
        {
          draft.triggers = action.payload
        }
        return
      case getType(notificationActions.setHasMore):
        {
          draft.hasMore = action.payload
        }
        return
      case getType(notificationActions.setSelectedTriggerId):
        {
          draft.selectedTriggerId = action.payload
        }
        return
      case getType(notificationActionsAsync.fetchNotifications.success):
        {
          const hasMore = action.payload.hasMore
          const merged: Notifications = { ...draft.notifications }
          const newNotifications = action.payload.notifications
          Object.keys(newNotifications).forEach(key => {
            merged[key] = newNotifications[key]
          })
          draft.notifications = merged
          draft.hasMore = hasMore
        }
        return
      case getType(notificationActionsAsync.fetchNotificationsByTime.success):
        {
          const { notifications } = action.payload
          draft.recentNotifications = notifications
        }
        return
      case getType(notificationActionsAsync.refreshAlerts.success):
        {
          const { notifications, fetchedTime } = action.payload
          if (!notifications) return
          const mergedAlerts = { ...draft.toastAlerts }
          Object.values(notifications).forEach(n => {
            if (n.alertRuleId) mergedAlerts[n.notificationId] = n
          });
          draft.toastAlerts = mergedAlerts
          draft.lastFetchedAlerts = fetchedTime
        }
        return
      case getType(notificationActionsAsync.editNotificationsState.success):
        {
          const merged: Notifications = { ...draft.notifications }
          const newNotifications = action.payload.notifications
          Object.keys(newNotifications).forEach(key => {
            merged[key] = newNotifications[key]
          })
          draft.notifications = merged
        }
        return
      case getType(notificationActionsAsync.createNotification.success):
        {
          const n = action.payload.notification
          draft.notifications[n.notificationId] = n
          draft.pastNotifications[n.notificationId] = n
          draft.recentNotifications[n.notificationId] = n
        }
        return
      case getType(notificationActionsAsync.fetchTriggers.success):
        {
          draft.triggers = { ...draft.triggers, ...action.payload.triggers }
        }
        return
      case getType(notificationActionsAsync.createTrigger.success):
        {
          const newTrigger = action.payload.trigger
          draft.triggers[newTrigger.triggerId] = newTrigger
        }
        return
      case getType(notificationActionsAsync.fetchTopics.success):
        {
          draft.topics = { ...draft.topics, ...action.payload.topics }
        }
        return
      case getType(notificationActionsAsync.fetchSubscribers.success):
        {
          draft.subscribers = { ...draft.subscribers, ...action.payload.subscribers }
        }
        return
      case getType(notificationActionsAsync.createTopic.success):
        {
          const topic = action.payload.topic
          draft.topics[topic.topicId] = topic
        }
        return
      case getType(notificationActionsAsync.updateTopic.success):
        {
          const topic = action.payload.topic
          if (topic) draft.topics[topic.topicId] = topic
        }
        return
      case getType(notificationActionsAsync.deleteTopic.success):
        {
          const topicId = action.payload.topicId
          if (draft.topics[topicId]) delete draft.topics[topicId]
        }
        return
      case getType(notificationActionsAsync.unsubscribe.success):
        {
          const subscriberId = action.payload.subscriberId
          if (draft.subscribers[subscriberId]) delete draft.subscribers[subscriberId]
        }
        return
      case getType(notificationActionsAsync.subscribe.success):
        {
          const subscriber = action.payload.subscriber
          if (!subscriber) return;
          let subscribers: Subscribers = { ...draft.subscribers }
          subscribers[subscriber.subscriberId] = subscriber
          draft.subscribers = subscribers
        }
        return
      case getType(notificationActionsAsync.confirmSubscription.success):
        {
          draft.subscribers = { ...draft.subscribers, ...action.payload.subscribers }
        }
        return
      case getType(notificationActionsAsync.updateTrigger.success):
        {
          const newTrigger = action.payload.trigger
          if (newTrigger) draft.triggers[newTrigger.triggerId] = newTrigger
        }
        return
      case getType(notificationActionsAsync.deleteTrigger.success):
        {
          const triggerId = action.payload.triggerId
          if (draft.triggers[triggerId]) {
            delete draft.triggers[triggerId]
          }
        }
        return
      case getType(notificationActionsAsync.fetchTriggerPeriods.success):
        {
          const { past, triggerPeriods } = action.payload
          if (past) draft.pastTriggerPeriods = triggerPeriods
          else draft.triggerPeriods = triggerPeriods 
        }
        return
    }
  },
  initialNotificationsState
)

export default notificationsReducer

export const fetchNotificationsEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf([
      notificationActionsAsync.fetchNotifications.request,
      tenantActionsAsync.selectProject.success,
    ])),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { notificationsUrl } = state.constants
        const { offset } = action.payload as { offset?: number }
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId
        const limit = 10

        async function fetchNotifications() {
          const token = client ? await client.getTokenSilently() : ""
          const notifications = await dependencies.notificationsAPI.fetchNotifications(
            {
              notificationsUrl,
              projectId,
              token,
              offset,
              limit,
              alerts: false
            }
          )

          observer.next(
            notificationActionsAsync.fetchNotifications.success({
              notifications,
              hasMore: Object.keys(notifications).length === limit,
            })
          )
        }
        fetchNotifications().catch(error =>
          observer.next(
            notificationActionsAsync.fetchNotifications.failure({ error })
          )
        )
      })
    })
  )
}

export const fetchAlertsEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf([
      tenantActionsAsync.selectProject.success,
    ])),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { notificationsUrl } = state.constants
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId
        const limit = 10

        async function fetchNotifications() {
          const token = client ? await client.getTokenSilently() : ""
          const to = new Date();
          const from = new Date();
          from.setDate(from.getDate()-ALERTS_DAYS)
          const notifications = await dependencies.notificationsAPI.fetchNotificationsByTime(
            {
              notificationsUrl,
              projectId,
              token,
              from,
              to,
              alerts: true
            }
          )

          observer.next(
            notificationActionsAsync.fetchNotifications.success({
              notifications,
              hasMore: Object.keys(notifications).length === limit,
            })
          )
        }
        fetchNotifications().catch(error =>
          observer.next(
            notificationActionsAsync.fetchNotifications.failure({ error })
          )
        )
      })
    })
  )
}

export const fetchNotificationsByTimeEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.fetchNotificationsByTime.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { notificationsUrl } = state.constants
        const { to, from } = action.payload
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId

        async function fetchNotifications() {
          const token = client ? await client.getTokenSilently() : ""
          const notifications = await dependencies.notificationsAPI.fetchNotificationsByTime(
            {
              notificationsUrl,
              from,
              to,
              projectId,
              token,
            }
          )

          observer.next(
            notificationActionsAsync.fetchNotificationsByTime.success({
              notifications,
            })
          )
        }
        fetchNotifications().catch(error =>
          observer.next(
            notificationActionsAsync.fetchNotificationsByTime.failure({ error })
          )
        )
      })
    })
  )
}

export const editNotificationsStateEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.editNotificationsState.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { notificationsUrl } = state.constants
        const { notificationIds, body } = action.payload
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId
        const limit = 10

        async function editNotificationsState() {
          const token = client ? await client.getTokenSilently() : ""
          const notifications = await dependencies.notificationsAPI.updateNotifications(
            {
              notificationsUrl,
              projectId,
              token,
              body,
              notificationIds,
            }
          )

          observer.next(
            notificationActionsAsync.editNotificationsState.success({
              notifications,
            })
          )
        }
        editNotificationsState().catch(error =>
          observer.next(
            notificationActionsAsync.fetchNotifications.failure({ error })
          )
        )
      })
    })
  )
}

/**
 * Triggers
 */
export const triggersFetchEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.fetchTriggers.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { notificationsUrl } = state.constants
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId

        async function fetchTriggers() {
          const token = client ? await client.getTokenSilently() : ""
          const triggers = await dependencies.notificationsAPI.fetchTriggers({
            notificationsUrl,
            projectId,
            token,
          })

          observer.next(
            notificationActionsAsync.fetchTriggers.success({
              triggers,
            })
          )
        }
        fetchTriggers().catch(error =>
          observer.next(
            notificationActionsAsync.fetchTriggers.failure({ error })
          )
        )
      })
    })
  )
}

export const triggersCreateEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.createTrigger.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { notificationsUrl } = state.constants
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId
        const { trigger } = action.payload

        async function createTrigger() {
          const token = client ? await client.getTokenSilently() : ""
          const newTrigger = await dependencies.notificationsAPI.createTrigger({
            notificationsUrl,
            projectId,
            token,
            trigger,
          })

          observer.next(
            notificationActionsAsync.createTrigger.success({
              trigger: newTrigger,
            })
          )
          observer.next(
            notificationActionsAsync.fetchTopics.request({})
          )
        }
        createTrigger().catch(error =>
          observer.next(
            notificationActionsAsync.createTrigger.failure({ error })
          )
        )
      })
    })
  )
}

export const triggersUpdateEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.updateTrigger.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { notificationsUrl } = state.constants
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId
        const { triggerId, trigger } = action.payload

        async function updateTrigger() {
          const token = client ? await client.getTokenSilently() : ""
          const newTrigger = await dependencies.notificationsAPI.updateTrigger({
            notificationsUrl,
            projectId,
            token,
            trigger,
            triggerId,
          })

          observer.next(
            notificationActionsAsync.updateTrigger.success({
              trigger: newTrigger,
            })
          )
        }
        updateTrigger().catch(error =>
          observer.next(
            notificationActionsAsync.updateTrigger.failure({ error })
          )
        )
      })
    })
  )
}

export const triggersDeleteEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.deleteTrigger.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { notificationsUrl } = state.constants
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId
        const { triggerId } = action.payload

        async function deleteTrigger() {
          const token = client ? await client.getTokenSilently() : ""
          await dependencies.notificationsAPI.deleteTrigger({
            notificationsUrl,
            projectId,
            token,
            triggerId,
          })

          observer.next(
            notificationActionsAsync.deleteTrigger.success({
              triggerId,
            })
          )
        }
        deleteTrigger().catch(error =>
          observer.next(
            notificationActionsAsync.deleteTrigger.failure({ error })
          )
        )
      })
    })
  )
}

export const triggerPeriodsFetchEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.fetchTriggerPeriods.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { notificationsUrl } = state.constants
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId
        const { start, end, past } = action.payload

        async function fetchTriggerPeriods() {
          const token = client ? await client.getTokenSilently() : ""
          const triggerPeriods = await dependencies.notificationsAPI.fetchTriggerPeriods({
            notificationsUrl,
            projectId,
            token,
            start,
            end
          })

          observer.next(
            notificationActionsAsync.fetchTriggerPeriods.success({
              triggerPeriods: formatTriggerPeriods(triggerPeriods),
              past
            })
          )
        }
        fetchTriggerPeriods().catch(error =>
          observer.next(
            notificationActionsAsync.fetchTriggerPeriods.failure({ error })
          )
        )
      })
    })
  )
}

export const noteAddEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.addNote.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { message } = action.payload


        async function createNotification() {
          observer.next(
            notificationActionsAsync.createNotification.request({ message })
          )
          observer.next(
            notificationActionsAsync.addNote.success({})
          )
        }
        createNotification().catch(error =>
          observer.next(
            notificationActionsAsync.addNote.failure({ error })
          )
        )
      })
    })
  )
}
export const notificationCreateEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.createNotification.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { notificationsUrl } = state.constants
        const client = state.auth0.auth0Client
        const userId = state.auth0.auth0User?.sub
        const projectId = state.tenant.selectedProjectId
        const selectedTimestamp = state.app.selectedTimestamp
        const { message } = action.payload

        const { filteredZone, filteredCalibration } = state.app

        const notification: NotificationsAttribute = {
          notificationId: v4(),
          projectId,
          startTimestamp: selectedTimestamp,
          endTimestamp: selectedTimestamp,
          dismissed: false,
          dismissedBy: undefined,
          read: false,
          readBy: undefined,
          alertRuleId: undefined,
          message,
          meta: {
            type: "note",
            zoneId: filteredZone,
            calibrationId: `${filteredCalibration}`,
            userId,
          },
        }

        async function createNotification() {
          const token = client ? await client.getTokenSilently() : ""
          const newNotification = await dependencies.notificationsAPI.createNotification(
            {
              notificationsUrl,
              projectId,
              token,
              notification,
            }
          )

          observer.next(
            notificationActionsAsync.createNotification.success({
              notification: newNotification,
            })
          )
        }
        createNotification().catch(error =>
          observer.next(
            notificationActionsAsync.createNotification.failure({ error })
          )
        )
      })
    })
  )
}

export const topicsFetchEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.fetchTopics.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { apiGatewayUrl } = state.constants
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId

        async function fetchTopics() {
          const token = client ? await client.getTokenSilently() : ""
          const topics = await dependencies.notificationsAPI.fetchTopics({
            apiGatewayUrl,
            projectId,
            token,
          })

          observer.next(
            notificationActionsAsync.fetchTopics.success({
              topics,
            })
          )
        }
        fetchTopics().catch(error =>
          observer.next(
            notificationActionsAsync.fetchTopics.failure({ error })
          )
        )
      })
    })
  )
}

export const subscribersFetchEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.fetchSubscribers.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { apiGatewayUrl } = state.constants
        const client = state.auth0.auth0Client
        const { topicId } = action.payload
        // const projectId = state.tenant.selectedProjectId

        async function fetchSubscribers() {
          const token = client ? await client.getTokenSilently() : ""
          const subscribers = await dependencies.notificationsAPI.fetchSubscribers({
            apiGatewayUrl,
            token,
            topicId,
          })

          observer.next(
            notificationActionsAsync.fetchSubscribers.success({
              subscribers,
            })
          )
        }
        fetchSubscribers().catch(error =>
          observer.next(
            notificationActionsAsync.fetchSubscribers.failure({ error })
          )
        )
      })
    })
  )
}

export const topicUpdateEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.updateTopic.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { apiGatewayUrl } = state.constants
        const client = state.auth0.auth0Client
        const { topicId, topic } = action.payload

        async function updateTopic() {
          const token = client ? await client.getTokenSilently() : ""
          const updatedTopic = await dependencies.notificationsAPI.updateTopic({
            apiGatewayUrl,
            token,
            topicId,
            topic
          })

          observer.next(
            notificationActionsAsync.updateTopic.success({
              topic: updatedTopic
            })
          )
        }
        updateTopic().catch(error =>
          observer.next(
            notificationActionsAsync.updateTopic.failure({ error })
          )
        )
      })
    })
  )
}

export const topicCreateEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.createTopic.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { apiGatewayUrl } = state.constants
        const client = state.auth0.auth0Client
        const { name, triggerId } = action.payload

        async function createTopic() {
          const token = client ? await client.getTokenSilently() : ""
          const topic = await dependencies.notificationsAPI.createTopic({
            apiGatewayUrl,
            name,
            triggerId,
            token,
          })

          observer.next(
            notificationActionsAsync.createTopic.success({
              topic,
            })
          )
        }
        createTopic().catch(error =>
          observer.next(
            notificationActionsAsync.createTopic.failure({ error })
          )
        )
      })
    })
  )
}

export const topicDeleteEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.deleteTopic.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { apiGatewayUrl } = state.constants
        const client = state.auth0.auth0Client
        const { topicId } = action.payload

        async function deleteTopic() {
          const token = client ? await client.getTokenSilently() : ""
          await dependencies.notificationsAPI.deleteTopic({
            apiGatewayUrl,
            topicId,
            token,
          })

          observer.next(
            notificationActionsAsync.deleteTopic.success({
              topicId,
            })
          )
        }
        deleteTopic().catch(error =>
          observer.next(
            notificationActionsAsync.deleteTopic.failure({ error })
          )
        )
      })
    })
  )
}

export const unsubscribeEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.unsubscribe.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { apiGatewayUrl } = state.constants
        const client = state.auth0.auth0Client
        const { subscriberId } = action.payload

        async function unsubscribe() {
          const token = client ? await client.getTokenSilently() : ""
          await dependencies.notificationsAPI.unsubscribe({
            apiGatewayUrl,
            subscriberId,
            token,
          })

          observer.next(
            notificationActionsAsync.unsubscribe.success({
              subscriberId,
            })
          )
        }
        unsubscribe().catch(error =>
          observer.next(
            notificationActionsAsync.unsubscribe.failure({ error })
          )
        )
      })
    })
  )
}

export const subscribeEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.subscribe.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { apiGatewayUrl } = state.constants
        const client = state.auth0.auth0Client
        const { topicId, protocol, endpoint, name } = action.payload

        async function subscribe() {
          const token = client ? await client.getTokenSilently() : ""
           const subscriber = await dependencies.notificationsAPI.subscribe({
            apiGatewayUrl,
            token,
            topicId,
            protocol,
            endpoint,
            name
          })

          observer.next(notificationActionsAsync.subscribe.success({
            subscriber
          }))
        }
        subscribe().catch(error =>
          observer.next(
            notificationActionsAsync.subscribe.failure({ error })
          )
        )
      })
    })
  )
}

export const confirmSubscriptionEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.confirmSubscription.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { apiGatewayUrl } = state.constants
        const client = state.auth0.auth0Client
        const { topicId, name, verificationCode, endpoint } = action.payload

        async function confirmSubscription() {
          const token = client ? await client.getTokenSilently() : ""
          const subscribers = await dependencies.notificationsAPI.confirmSubscription({
            apiGatewayUrl,
            token,
            topicId,
            name,
            verificationCode,
            endpoint
          })

          observer.next(
            notificationActionsAsync.confirmSubscription.success({
              subscribers,
            })
          )
        }
        confirmSubscription().catch(error =>
          observer.next(
            notificationActionsAsync.confirmSubscription.failure({ error })
          )
        )
      })
    })
  )
}

export const vmsEventCreateEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(notificationActionsAsync.createVmsEvent.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const { notificationsUrl, apiGatewayUrl } = state.constants
        const client = state.auth0.auth0Client
        const projectId = state.tenant.selectedProjectId
        const selectedTimestamp = state.app.selectedTimestamp
        const { description, caption } = action.payload

        async function createVmsEvent() {
          const token = client ? await client.getTokenSilently() : ""
          const vmsMappings = await dependencies.vmsAPI.getMappings(token, apiGatewayUrl, projectId)
          const enabledVmsIds = vmsMappings 
            && vmsMappings.project_vms_maps.filter((m: any) => m.is_enabled).map((m: any) => m.id)
            || []
          enabledVmsIds.forEach(async (id: number) => {
            try {
              const vmsEvent = await dependencies.vmsAPI.createEvent(
                token,
                notificationsUrl,
                {
                  projectVmsMapId: id,
                  timestamp: selectedTimestamp.toISOString(),
                  caption,
                  description,
                },
              )
            } catch (e) {
              console.log(e)
            }
          })

          observer.next(
            notificationActionsAsync.createVmsEvent.success({})
          )
        }
        createVmsEvent().catch(error =>
          observer.next(
            notificationActionsAsync.createVmsEvent.failure({ error })
          )
        )
      })
    })
  )
}