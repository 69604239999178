import { combineReducers } from "@reduxjs/toolkit"
import { Epic, combineEpics } from "redux-observable"
import { catchError } from "rxjs/operators"
import tenantReducer, {
  tenantInitEpic,
  deploymentUpdateEpic,
  zoneUpdateEpic,
  siteOverlayUpdateEpic,
  siteUpdateEpic,
  siteOverlayCreateEpic,
  siteDeleteEpic,
  zoneDeleteEpic,
  deploymentDeleteEpic,
  zoneCreateEpic,
  // siteCreateEpic,
  deploymentCombinedCreateEpic,
  deploymentCombinedUpdateEpic,
  calibrationsCreateEpic,
  calibrationFloorplanUploadEpic,
  calibrationSatelliteUploadEpic,
  calibrationsUpdateEpic,
  calibrationStartEpic,
  calibrationsDeleteEpic,
  TenantState,
  deploymentCombinedDeleteEpic,
  projectUpdateEpic,
  calibrationGridUploadEpic,
  calibrationCameraCaptureEpic,
  calibrationCameraUploadEpic,
  projectSelectEpic,
  projectRefreshEpic,
  tenantRefreshEpic,
  projectCreateEpic,
  projectDeleteEpic,
  userInviteEpic,
  userCreateEpic,
  userDeleteEpic,
  userUpdateEpic,
  projectUsersUpdateEpic,
  projectCloneEpic,
  deploymentVerifyEpic,
  calibrationGridOnlyUploadEpic,
  calibrationMoodStartEpic,
  zoneConfigSaveEpic,
  getCameraSchedulesEpic,
  createCameraSchedulesEpic,
  updateCameraSchedulesEpic,
  deleteCameraSchedulesEpic,
  TenantAction,
} from "./tenantReducer"
import auth0Reducer, {
  auth0initEpic,
  Auth0State,
  auth0LogoutEpic,
  auth0LoginEpic,
  auth0ChangePasswordEpic,
  userProfileEditEpic,
  firstTimeADLoginEpic,
  Auth0Action,
} from "./auth0Reducer"
import appReducer, {
  AppState,
  fullscreenEpic,
  fullscreenListenerEpic,
  toggleDrawerEpic,
  playEpic,
  resizeEpic,
  showEpic,
  AppAction,
} from "./appReducer"
import projectReducer, {
  ProjectState,
  appPingDeviceEpic,
  calibrationCaptureMoodImageEpic,
  deploymentStatusesPollEpic,
  cameraSetupCreateEpic,
  cameraSetupDeleteEpic,
  deploymentStatusPollEpic,
  ProjectAction,
} from "./projectReducer"
import {
  ConstantsAction,
  constantsReducer,
  ConstantsState,
} from "./constantsReducer"
import { enablePatches } from "immer"
import {
  networkGetVpnProfileEpicV2,
  networkPollStatusEpicV2,
  NetworkSetupAction,
  networkSetupDestroyEpicV2,
  networkSetupGenerateEpicV2,
  networkSetupReducerV2,
  NetworkSetupState,
  networkVerifyDeviceEpicV2,
  networkVerifyGatewayEpicV2,
  networkVpnIpEpicV2,
} from "./networkSetupReducerV2"
import {
  FetchingAction,
  fetchingReducer,
  FetchingState,
} from "./fetchingReducer"
import { messagesReducer, MessagesState } from "./messagesReducer"
import dataV2Reducer, {
  DataV2Action,
  // dataV2InitEpic,
  dataV2RecalculateEpic,
  // dataV2RefreshEpic,
  DataV2State,
  // pastDataV2FetchEpic,
  dataV2TranslateCamToGeoEpic,
  fetchV2Epic,
  fetchV2RefreshEpic,
} from "./dataReducerV2"
import reportsReducer, {
  fetchReportEpic,
  getDayReportEpic,
  getWeekReportEpic,
  getMonthReportEpic,
  hasMetricDataEpic,
  getCrowdCapacityEpic,
  createCrowdCapacityEpic,
  getCumulativeCountsEpic,
  ReportAction,
  ReportsState,
} from "./reportsReducer"
import {
  dataV2InitEpicQueued,
  dataV2InitV2EpicQueued,
  dataV2InitLoadEpicQueued,
  dataV2RefreshEpicQueued,
} from "../controllers/dataV2FetchLiveController"
import {
  pastDataV2FetchEpicQueued,
  pastDataV2InitFetchEpicQueued,
  pastDataV2LoadFetchEpicQueued
} from "../controllers/dataV2FetchPastController"
import notificationsReducer, {
  editNotificationsStateEpic,
  fetchNotificationsEpic,
  fetchAlertsEpic,
  fetchNotificationsByTimeEpic,
  notificationCreateEpic,
  noteAddEpic,
  NotificationsAction,
  NotificationsState,
  triggersCreateEpic,
  triggersDeleteEpic,
  triggersFetchEpic,
  triggersUpdateEpic,
  triggerPeriodsFetchEpic,
  topicsFetchEpic,
  subscribersFetchEpic,
  topicCreateEpic,
  topicUpdateEpic,
  topicDeleteEpic,
  unsubscribeEpic,
  subscribeEpic,
  confirmSubscriptionEpic,
  vmsEventCreateEpic,
} from "./notifications"
import {
  socketInitEpic,
  socketProjectChangeEpic,
  notificationUpdateEpic,
} from "../controllers/socketIoController"

//Enable immer settings
enablePatches()

//COMBINE REDUCERS, STATE AND EPICS HERE
export interface IState {
  constants: ConstantsState
  app: AppState
  dataV2: DataV2State
  reports: ReportsState
  tenant: TenantState
  auth0: Auth0State
  project: ProjectState
  network: NetworkSetupState
  fetching: FetchingState
  messages: MessagesState
  notifications: NotificationsState
}

export type RootAction =
  | FetchingAction
  | ConstantsAction
  | NetworkSetupAction
  | Auth0Action
  | DataV2Action
  | ProjectAction
  | TenantAction
  | AppAction
  | ReportAction
  | NotificationsAction

const epics: Epic[] = [
  showEpic,
  auth0initEpic,
  auth0LoginEpic,
  auth0LogoutEpic,
  auth0ChangePasswordEpic,
  userProfileEditEpic,
  firstTimeADLoginEpic,
  tenantInitEpic,
  tenantRefreshEpic,
  fullscreenEpic,
  fullscreenListenerEpic,
  toggleDrawerEpic,
  resizeEpic,
  fetchReportEpic,
  getDayReportEpic,
  getWeekReportEpic,
  getMonthReportEpic,
  hasMetricDataEpic,
  getCrowdCapacityEpic,
  createCrowdCapacityEpic,
  getCumulativeCountsEpic,
  // dataV2InitEpic,
  dataV2InitEpicQueued,
  dataV2InitV2EpicQueued,
  dataV2InitLoadEpicQueued,
  // dataV2RefreshEpic,
  dataV2RefreshEpicQueued,
  // pastDataV2FetchEpic,
  fetchV2Epic,
  fetchV2RefreshEpic,
  pastDataV2FetchEpicQueued,
  pastDataV2InitFetchEpicQueued,
  pastDataV2LoadFetchEpicQueued,
  dataV2RecalculateEpic,
  dataV2TranslateCamToGeoEpic,
  playEpic,
  projectSelectEpic,
  projectRefreshEpic,
  calibrationsCreateEpic,
  calibrationsUpdateEpic,
  calibrationsDeleteEpic,
  calibrationStartEpic,
  calibrationMoodStartEpic,
  deploymentUpdateEpic,
  deploymentCombinedCreateEpic,
  deploymentCombinedUpdateEpic,
  deploymentCombinedDeleteEpic,
  deploymentDeleteEpic,
  deploymentVerifyEpic,
  deploymentStatusesPollEpic,
  deploymentStatusPollEpic,
  zoneCreateEpic,
  zoneUpdateEpic,
  zoneDeleteEpic,
  // siteCreateEpic,
  siteUpdateEpic,
  siteOverlayUpdateEpic,
  siteOverlayCreateEpic,
  siteDeleteEpic,
  zoneConfigSaveEpic,
  getCameraSchedulesEpic,
  createCameraSchedulesEpic,
  updateCameraSchedulesEpic,
  deleteCameraSchedulesEpic,
  projectCreateEpic,
  projectCloneEpic,
  projectUpdateEpic,
  projectDeleteEpic,
  projectUsersUpdateEpic,
  userCreateEpic,
  userInviteEpic,
  userUpdateEpic,
  userDeleteEpic,
  cameraSetupCreateEpic,
  cameraSetupDeleteEpic,
  calibrationFloorplanUploadEpic,
  calibrationSatelliteUploadEpic,
  calibrationGridUploadEpic,
  calibrationGridOnlyUploadEpic,
  calibrationCameraCaptureEpic,
  calibrationCameraUploadEpic,
  calibrationCaptureMoodImageEpic,
  networkPollStatusEpicV2,
  networkGetVpnProfileEpicV2,
  networkVpnIpEpicV2,
  networkVerifyGatewayEpicV2,
  networkVerifyDeviceEpicV2,
  networkSetupGenerateEpicV2,
  networkSetupDestroyEpicV2,
  fetchNotificationsEpic,
  fetchAlertsEpic,
  fetchNotificationsByTimeEpic,
  editNotificationsStateEpic,
  notificationCreateEpic,
  noteAddEpic,
  socketInitEpic,
  socketProjectChangeEpic,
  notificationUpdateEpic,
  triggersFetchEpic,
  triggersCreateEpic,
  triggersUpdateEpic,
  triggersDeleteEpic,
  triggerPeriodsFetchEpic,
  topicsFetchEpic,
  subscribersFetchEpic,
  topicCreateEpic,
  topicUpdateEpic,
  topicDeleteEpic,
  unsubscribeEpic,
  subscribeEpic,
  confirmSubscriptionEpic,
  vmsEventCreateEpic,
  appPingDeviceEpic,
]
export const rootEpic: Epic = (action$, store$, dependencies) =>
  combineEpics(...epics)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      // console.error(error)
      return source
    })
  )
export const rootReducer = combineReducers({
  constants: constantsReducer,
  app: appReducer,
  dataV2: dataV2Reducer,
  reports: reportsReducer,
  tenant: tenantReducer,
  auth0: auth0Reducer,
  project: projectReducer,
  network: networkSetupReducerV2,
  fetching: fetchingReducer,
  messages: messagesReducer,
  notifications: notificationsReducer,
})
