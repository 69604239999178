import { Epic } from "redux-observable"
import {
  map,
  switchMap,
  catchError,
  retryWhen,
  delay,
  filter,
} from "rxjs/operators"
import { Observable, interval } from "rxjs"
import { produce, Draft } from "immer"
import { IState, RootAction } from "."
import {
  IUserProfileV2,
  UserOrgPermissionsAttribute,
  UserPermissionsAttribute,
  UserSettings,
  UserPrivileges,
} from "../../@types/IUserProfileV2"
import { IProjectData } from "../../@types/IProjectData"
import { DeploymentConfig, IDeployment } from "../../@types/IDeployment"
import { IZone } from "../../@types/IZone"
import { IProject } from "../../@types/IProject"
import { ISite } from "../../@types/ISite"
import { IDevice } from "../../@types/IDevice"
import { deleteModel } from "../apis/deleteEpic"
import { ICalibration } from "../../@types/ICalibration"
import { ICrowdCapacityReport } from "../../@types/ICrowdCapacityReport"
import { ITrains } from "../../@types/ITrains"
import { ICarriages } from "../../@types/ICarriages"
import { ICounters } from "../../@types/ICounters";
import { IUser } from "../../@types/IUser"
import { IOrganisation } from "../../@types/IOrganisation"
import { CompoundMapping } from "../../@types/ICompounds"
import { Schedule, Recurrence } from "../../@types/CameraSchedule"
import { v4 } from "uuid"
import { networkSetupActionsAsync } from "./networkSetupReducerV2"
import {
  createAction,
  createAsyncAction,
  getType,
  isActionOf,
} from "typesafe-actions"
import { auth0ActionsAsync } from "./auth0Reducer"
import { dataV2Actions, dataV2ActionsAsync } from "./dataReducerV2"
import { appActions } from "./appReducer";
import { userOrganisationSelector } from "../selectors/tenantSelectors"
import { createUpdateEpic } from "../epics/createUpdateEpic"
import { createDeleteEpic } from "../epics/createDeleteEpic"
import { createCreateEpic } from "../epics/createCreateEpic"
import { Dependencies } from "../ReduxProvider"
import { DEFAULT_CENTER, DEFAULT_INTERVAL, DASHBOARD_PATH, HOME } from "../../constants"
import { notificationActionsAsync } from "./notifications";
import { toDate, getLocaleDateString, getLocaleTimeString } from "../../utils/date"; 
import { navigate } from "@reach/router"

/**
 * ==============================================================
 * STATE
 * ==============================================================
 */
export type Permissions = { [userId: string]: string }
export interface TenantState {
  tenantFetching: boolean
  projectCreated: boolean
  selectedProjectId: string

  organisations: { [organisationId: string]: IOrganisation }
  users: { [userId: string]: IUser }

  user: any //TODO - though this is stored in auth0client
  userPermissions: {
    org: { [organisationId: string]: string }
    project: { [projectId: string]: string }
  }
  userSettings: UserSettings
  userPrivileges?: UserPrivileges
  organisationPermissions: {
    [id: string]: UserOrgPermissionsAttribute
  }
  projectPermissions: {
    [id: string]: UserPermissionsAttribute
  }

  projects: { [id: string]: IProject }
  sites: { [id: string]: ISite }
  zones: { [id: string]: IZone }
  deployments: { [id: string]: IDeployment }
  devices: { [id: string]: IDevice }
  calibrations: { [id: string]: ICalibration }
  crowdCapacityReports: { [id: string]: ICrowdCapacityReport }
  trains: { [id: string]: ITrains }
  carriages: { [id: string]: ICarriages }
  counters: { [id: string]: ICounters }
  compounds: { [id: string]: CompoundMapping }

  schedules: { [id: string]: Schedule }
  recurrences: { [id: string]: Recurrence }

  dashV2SiteId: string
}

export const initialTenantState: TenantState = {
  tenantFetching: true,
  projectCreated: true, //TODO: for now, might not be needed later
  selectedProjectId: "",

  organisations: {},
  users: {},

  user: {},
  userPermissions: {
    org: {},
    project: {},
  },
  userSettings: {},
  organisationPermissions: {},
  projectPermissions: {},

  projects: {},
  sites: {},
  zones: {},
  deployments: {},
  devices: {},
  calibrations: {},
  crowdCapacityReports: {},
  trains: {},
  carriages: {},
  counters: {},
  compounds: {},

  // Camera Scheduler
  schedules: {},
  recurrences: {},

  dashV2SiteId: "",
}

/**
 * ==============================================================
 * ACTIONS
 * ==============================================================
 */
export const tenantActions = {
  test: createAction("@tenant/test", (test: string) => test)(),
  selectDashV2SiteId: createAction("@tenant/selectDashV2Site", (siteId: string) => siteId)(),
  updateUserSettings: createAction("@tenant/updateUserSettings", (userSettings: UserSettings) => userSettings)(),
  navDashboard: createAction("@app/navDashboard")(),
}
export const tenantActionsAsync = {
  init: createAsyncAction(
    "@tenant/init/req",
    "@tenant/init/res",
    "@tenant/init/err"
  )<
    {},
    { userProfile: IUserProfileV2; projectId?: string },
    { error: Error }
  >(),
  selectProject: createAsyncAction(
    "@tenant/selectProject/req",
    "@tenant/selectProject/res",
    "@tenant/selectProject/err"
  )<
    { projectId: string; center?: [number, number] },
    { projectData: IProjectData; projectId: string },
    { error: Error }
  >(),
  refreshProject: createAsyncAction(
    "@tenant/refreshProject/req",
    "@tenant/refreshProject/res",
    "@tenant/refreshProject/err"
  )<
    { projectId: string },
    { projectData: IProjectData; projectId: string },
    { error: Error }
  >(),
  createProject: createAsyncAction(
    "@tenant/createProject/req",
    "@tenant/createProject/res",
    "@tenant/createProject/err"
  )<
    { project: Partial<IProject> },
    { projectPermissions: UserPermissionsAttribute[]; project: IProject },
    { error: Error }
  >(),
  cloneProject: createAsyncAction(
    "@tenant/cloneProject/req",
    "@tenant/cloneProject/res",
    "@tenant/cloneProject/err"
  )<
    { projectId: string },
    { projectPermissions: UserPermissionsAttribute[]; project: IProject },
    { error: Error }
  >(),
  updateProject: createAsyncAction(
    "@tenant/updateProject/req",
    "@tenant/updateProject/res",
    "@tenant/updateProject/err"
  )<
    { id: string; body: Partial<IProject> },
    { resObj: any },
    { error: Error }
  >(),
  deleteProject: createAsyncAction(
    "@tenant/deleteProject/req",
    "@tenant/deleteProject/res",
    "@tenant/deleteProject/err"
  )<{ projectId: string }, { projectId: string }, { error: Error }>(),
  createDeployment: createAsyncAction(
    "@tenant/createDeployment/req",
    "@tenant/createDeployment/res",
    "@tenant/createDeployment/err"
  )<{ body: Partial<IDeployment> }, {}, { error: Error }>(),
  updateDeployment: createAsyncAction(
    "@tenant/updateDeployment/req",
    "@tenant/updateDeployment/res",
    "@tenant/updateDeployment/err"
  )<
    { id: string; body: Partial<IDeployment> },
    { resObj: any },
    { error: Error }
  >(),
  deleteDeployment: createAsyncAction(
    "@tenant/deleteDeployment/req",
    "@tenant/deleteDeployment/res",
    "@tenant/deleteDeployment/err"
  )<{}, { id: string }, { error: Error }>(),
  createSiteOverlay: createAsyncAction(
    "@tenant/createSiteOverlay/req",
    "@tenant/createSiteOverlay/res",
    "@tenant/createSiteOverlay/err"
  )<
    {
      projectId: string
      imageChanged: boolean
      imageFile: File
      id: string
      body: Partial<ISite>
    },
    { resObj: ISite },
    { error: Error }
  >(),
  //Not used
  updateSite: createAsyncAction(
    "@tenant/updateSite/req",
    "@tenant/updateSite/res",
    "@tenant/updateSite/err"
  )<
    { id: string; body: Partial<ISite> },
    { resObj: ISite },
    { error: Error }
  >(),
  updateSiteOverlay: createAsyncAction(
    "@tenant/updateSiteOverlay/req",
    "@tenant/updateSiteOverlay/res",
    "@tenant/updateSiteOverlay/err"
  )<
    {
      projectId: string
      imageChanged: boolean
      imageFile: File
      id: string
      body: Partial<ISite>
    },
    { resObj: ISite },
    { error: Error }
  >(),
  deleteSite: createAsyncAction(
    "@tenant/deleteSite/req",
    "@tenant/deleteSite/res",
    "@tenant/deleteSite/err"
  )<{}, { id: string }, { error: Error }>(),
  createZone: createAsyncAction(
    "@tenant/createZone/req",
    "@tenant/createZone/res",
    "@tenant/createZone/err"
  )<
    {
      body: Partial<IZone>
    },
    { resObj: IZone },
    { error: Error }
  >(),
  updateZone: createAsyncAction(
    "@tenant/updateZone/req",
    "@tenant/updateZone/res",
    "@tenant/updateZone/err"
  )<
    {
      id: string
      body: Partial<IZone>
    },
    { resObj: IZone },
    { error: Error }
  >(),
  deleteZone: createAsyncAction(
    "@tenant/deleteZone/req",
    "@tenant/deleteZone/res",
    "@tenant/deleteZone/err"
  )<{}, { id: string }, { error: Error }>(),
  saveZoneConfig: createAsyncAction(
    "@tenant/saveZoneConfig/req",
    "@tenant/saveZoneConfig/res",
    "@tenant/saveZoneConfig/err"
  )<
    {
      zoneId: string
      zoneData: Partial<IZone>
      deploymentConfigs: {
        [id: string]: DeploymentConfig
      }
    },
    { deployments: IDeployment[]; zone: IZone },
    { error: Error }
  >(),
  createDeploymentCombined: createAsyncAction(
    "@tenant/createDeploymentCombined/req",
    "@tenant/createDeploymentCombined/res",
    "@tenant/createDeploymentCombined/err"
  )<
    {
      device: IDevice
      deployment: IDeployment
    },
    {
      newDeployment: IDeployment
      newDevice: IDevice
    },
    { error: Error }
  >(),
  updateDeploymentCombined: createAsyncAction(
    "@tenant/updateDeploymentCombined/req",
    "@tenant/updateDeploymentCombined/res",
    "@tenant/updateDeploymentCombined/err"
  )<
    {
      device: Partial<IDevice>
      deployment: Partial<IDeployment>
      deviceId: string
      deploymentId: string
    },
    {
      newDeployment: IDeployment
      newDevice: IDevice
    },
    { error: Error }
  >(),
  deleteDeploymentCombined: createAsyncAction(
    "@tenant/deleteDeploymentCombined/req",
    "@tenant/deleteDeploymentCombined/res",
    "@tenant/deleteDeploymentCombined/err"
  )<
    {
      deviceId: string
      deploymentId: string
    },
    {
      deviceId: string
      deploymentId: string
    },
    { error: Error }
  >(),
  verifyDeployment: createAsyncAction(
    "@tenant/verifyDeployment/req",
    "@tenant/verifyDeployment/res",
    "@tenant/verifyDeployment/err"
  )<
    {
      projectId: string
      deploymentId: string
    },
    {
      deploymentVerification: string
    },
    { error: Error; message: string; code: string }
  >(),
  createCalibration: createAsyncAction(
    "@tenant/createCalibration/req",
    "@tenant/createCalibration/res",
    "@tenant/createCalibration/err"
  )<
    {
      body: Partial<ICalibration>
    },
    { resObj: ICalibration },
    { error: Error }
  >(),
  updateCalibration: createAsyncAction(
    "@tenant/updateCalibration/req",
    "@tenant/updateCalibration/res",
    "@tenant/updateCalibration/err"
  )<
    {
      id: string
      body: Partial<ICalibration>
    },
    { resObj: ICalibration },
    { error: Error }
  >(),
  deleteCalibration: createAsyncAction(
    "@tenant/deleteCalibration/req",
    "@tenant/deleteCalibration/res",
    "@tenant/deleteCalibration/err"
  )<{}, { id: string }, { error: Error }>(),
  uploadCalibrationFloorplan: createAsyncAction(
    "@tenant/uploadCalibrationFloorplan/req",
    "@tenant/uploadCalibrationFloorplan/res",
    "@tenant/uploadCalibrationFloorplan/err"
  )<
    {
      projectId: string
      calibrationId: string
      deploymentId: number
      floorplanorientation: string
      image: File
      metrepixelratio: string
      lat: number
      lng: number
    },
    { resObj: ICalibration },
    { error: Error; message: string; code: string }
  >(),
  uploadCalibrationSatellite: createAsyncAction(
    "@tenant/uploadCalibrationSatellite/req",
    "@tenant/uploadCalibrationSatellite/res",
    "@tenant/uploadCalibrationSatellite/err"
  )<
    {
      projectId: string
      deploymentId: number
      calibrationId: string
      provider: string
      zoom: number
      floorplanOrientation: string
      lat: number
      lng: number
    },
    { resObj: ICalibration },
    { error: Error; message: string; code: string }
  >(),
  uploadCalibrationGrid: createAsyncAction(
    "@tenant/uploadCalibrationGrid/req",
    "@tenant/uploadCalibrationGrid/res",
    "@tenant/uploadCalibrationGrid/err"
  )<
    {
      projectId: string
      deploymentId: number
      gridWidth: string
      gridCount: number
      gridRotation: string
      calibrationId: string
      opacity: number
      lat: number
      lng: number
    },
    { resObj: ICalibration },
    { error: Error; message: string; code: string }
  >(),
  uploadCalibrationGridOnly: createAsyncAction(
    "@tenant/uploadCalibrationGridOnly/req",
    "@tenant/uploadCalibrationGridOnly/res",
    "@tenant/uploadCalibrationGridOnly/err"
  )<
    {
      projectId: string
      deploymentId: number
      gridWidth: string
      gridCount: number
      gridRotation: string
      calibrationId: string
      lat: number
      lng: number
    },
    { resObj: ICalibration },
    { error: Error; message: string; code: string }
  >(),
  startCalibration: createAsyncAction(
    "@tenant/startCalibration/req",
    "@tenant/startCalibration/res",
    "@tenant/startCalibration/err"
  )<
    {
      projectId: string
      calibrationId: string
      pointsMapView: string
      pointsDensityView: string
    },
    { resObj: ICalibration },
    { error: Error; message: string; code: string }
  >(),
  startMoodCalibration: createAsyncAction(
    "@tenant/startMoodCalibration/req",
    "@tenant/startMoodCalibration/res",
    "@tenant/startMoodCalibration/err"
  )<
    {
      projectId: string
      calibrationId: string
    },
    { resObj: ICalibration },
    { error: Error; message: string; code: string }
  >(),
  captureCalibrationImage: createAsyncAction(
    "@tenant/captureCalibrationImage/req",
    "@tenant/captureCalibrationImage/res",
    "@tenant/captureCalibrationImage/err"
  )<
    {
      projectId: string
      deploymentId: number
      calibrationId: string
      densityvieworientation: string
    },
    { resObj: ICalibration },
    { error: Error; message: string; code: string }
  >(),
  uploadCalibrationImage: createAsyncAction(
    "@tenant/uploadCalibrationImage/req",
    "@tenant/uploadCalibrationImage/res",
    "@tenant/uploadCalibrationImage/err"
  )<
    {
      projectId: string
      deploymentId: number
      calibrationId: string
      imageFile: File
      densityvieworientation: string
    },
    { resObj: ICalibration },
    { error: Error; message: string; code: string }
  >(),
  captureMoodCalibrationImage: createAsyncAction(
    "@tenant/captureMoodCalibrationImage/req",
    "@tenant/captureMoodCalibrationImage/res",
    "@tenant/captureMoodCalibrationImage/err"
  )<
    {
      moodCornerLoading: string
      calibrationId: string
      moodview: string
    },
    { resObj: ICalibration },
    { error: Error; message: string; code: string }
  >(),
  createUser: createAsyncAction(
    "@tenant/createUser/req",
    "@tenant/createUser/res",
    "@tenant/createUser/err"
  )<
    {
      user: {
        given_name: string
        family_name: string
        email: string
        password: string
        user_metadata: {
          phone: string
        }
      }
      role: string
    },
    {},
    { error: Error; message: string }
  >(),
  inviteUser: createAsyncAction(
    "@tenant/inviteUser/req",
    "@tenant/inviteUser/res",
    "@tenant/inviteUser/err"
  )<
    {
      user: {
        email: string
        password: string
      }
      role: string
    },
    {
      user: IUser
      userId: string
      permission: UserOrgPermissionsAttribute
      organisationPermissions: {
        [id: string]: UserPermissionsAttribute
      }
    },
    { error: Error; message: string }
  >(),
  updateUser: createAsyncAction(
    "@tenant/updateUser/req",
    "@tenant/updateUser/res",
    "@tenant/updateUser/err"
  )<
    {
      userId: string
      user: Partial<IUser> & { password?: string }
      role?: string
      userSettings?: Partial<UserSettings>
    },
    {
      user: IUser
      userId: string
      permission: UserOrgPermissionsAttribute
      organisationPermissions: {
        [id: string]: UserPermissionsAttribute
      },
      userSettings: UserSettings
    },
    { error: Error; message: string }
  >(),
  deleteUser: createAsyncAction(
    "@tenant/deleteUser/req",
    "@tenant/deleteUser/res",
    "@tenant/deleteUser/err"
  )<
    {
      userId: string
    },
    {
      userId: string
    },
    { error: Error; message: string }
  >(),
  updateProjectUser: createAsyncAction(
    "@tenant/updateProjectUser/req",
    "@tenant/updateProjectUser/res",
    "@tenant/updateProjectUser/err"
  )<
    {
      permissions: Permissions
      initialPermissions: Permissions
      projectId: string
    },
    {
      projectPermissions: {
        [id: string]: UserPermissionsAttribute
      }
    },
    { error: Error; message: string }
  >(),
  getCameraSchedules: createAsyncAction(
    "@tenant/getCameraSchedules/req",
    "@tenant/getCameraSchedules/res",
    "@tenant/getCameraSchedules/err"
  )<
    {
      projectId: string,
      isEnabled: boolean,
      isDeleted: boolean,
    },
    {
      schedules: Schedule[],
      recurrences: Recurrence[]
    },
    { error: Error }
  >(),
  createCameraSchedule: createAsyncAction(
    "@tenant/createCameraSchedule/req",
    "@tenant/createCameraSchedule/res",
    "@tenant/createCameraSchedule/err"
  )<
    {
      schedule: any,
      recurrence?: any,
    },
    {
    },
    { error: Error }
  >(),
  updateCameraSchedule: createAsyncAction(
    "@tenant/updateCameraSchedule/req",
    "@tenant/updateCameraSchedule/res",
    "@tenant/updateCameraSchedule/err"
  )<
    {
      scheduleId?: string,
      schedule?: any,
      scheduleRecurrenceId?: string,
      recurrence?: any,
    },
    {
    },
    { error: Error }
  >(),
  deleteCameraSchedule: createAsyncAction(
    "@tenant/deleteCameraSchedule/req",
    "@tenant/deleteCameraSchedule/res",
    "@tenant/deleteCameraSchedule/err"
  )<
    {
      projectId?: string,
      scheduleId?: string,
      scheduleRecurrenceId?: string,
    },
    {},
    { error: Error }
  >(),
}

type ValueOf<T> = T[keyof T]
export type TenantAction =
  | ReturnType<ValueOf<typeof tenantActions>>
  | ReturnType<ValueOf<ValueOf<typeof tenantActionsAsync>>>

/**
 * ==============================================================
 * REDUCERS
 * ==============================================================
 */

/**
 * Delete functions
 */
function deleteSite(draft: Draft<TenantState>, id: string) {
  // delete draft.sites[id]
  //#0ff we mark for deletion instead
  const site = draft.sites[id]
  if (!site) return
  draft.sites[id] = { ...site, deleting: true }

  const zone = Object.values(draft.zones).find(z => z.siteId === id)
  if (zone) deleteZone(draft, zone.zoneId)
}
function deleteZone(draft: Draft<TenantState>, id: string) {
  // delete draft.zones[id]
  //#0ff we mark for deletion instead
  const zone = draft.zones[id]
  if (!zone) return
  draft.zones[id] = { ...zone, deleting: true }

  const deployment = Object.values(draft.deployments).find(d => d.zoneId === id)
  if (deployment) {
    // const device = Object.values(draft.devices).find(
    //   d => d.deviceId === deployment.deviceId
    // )
    // if (device) delete draft.devices[device.deviceId]
    deleteDeployment(draft, `${deployment.deploymentId}`)
  }
}
function deleteDeployment(draft: Draft<TenantState>, id: string) {
  //#0ff we mark for deletion instead
  const deployment = draft.deployments[id]
  if (!deployment) return
  draft.deployments[id] = { ...deployment, deleting: true }

  // delete draft.deployments[id]
  const calibrations = Object.values(draft.calibrations)
    .filter(c => c.deploymentId === +id)
    .map(c => {
      // delete draft.calibrations[calibration.calibrationId]

      //#0ff we mark for deletion instead
      const calibration = draft.calibrations[c.calibrationId]
      if (!calibration) return
      draft.calibrations[id] = { ...calibration, deleting: true }
    })
}

export const tenantReducer = produce(
  (draft: Draft<TenantState>, action: RootAction) => {
    switch (action.type) {
      case getType(tenantActions.selectDashV2SiteId):
        {
          draft.dashV2SiteId = action.payload;
        }
        return
      case getType(tenantActions.navDashboard): {
        const project = draft.projects[draft.selectedProjectId]
        typeof window !== "undefined" && navigate(project && DASHBOARD_PATH[project.default_dashboard_id] || HOME)
        return
      }
      case getType(tenantActions.updateUserSettings):
        {
          draft.userSettings = action.payload;
        }
        return
      case getType(tenantActionsAsync.init.success):
        {
          draft.tenantFetching = false
          const userProfile = action.payload.userProfile as IUserProfileV2
          const selectedProjectId = action.payload.projectId
          draft.user = userProfile.user || {}
          draft.userSettings = userProfile.userSettings || {}
          if (userProfile.userPrivileges) draft.userPrivileges = userProfile.userPrivileges || {}
          draft.projects = userProfile.projects || {}
          draft.organisations = userProfile.organisations || {}
          draft.users = userProfile.users || {}
          draft.userPermissions = userProfile.userPermissions || {
            org: {},
            project: {},
          }
          draft.projectPermissions = userProfile.projectPermissions || {}
          draft.organisationPermissions =
            userProfile.organisationPermissions || {}
          //Handle selected projectId
          if (selectedProjectId) {
            draft.selectedProjectId = selectedProjectId
          }
        }
        return
      case getType(tenantActionsAsync.selectProject.success):
        {
          const projectData = action.payload.projectData
          const projectId = action.payload.projectId
          draft.sites = projectData.sites || {}
          draft.zones = projectData.zones || {}
          draft.devices = projectData.devices || {}
          draft.deployments = projectData.deployments || {}
          draft.calibrations = projectData.calibrations || {}
          draft.crowdCapacityReports = projectData.crowdCapacityReports || {}
          draft.trains = projectData.trains || {}
          draft.carriages = projectData.carriages || {}
          draft.counters = projectData.counters || {}
          draft.selectedProjectId = projectId
          draft.compounds = projectData.compounds || {}
        }
        return
      case getType(tenantActionsAsync.refreshProject.success):
        {
          const projectId = action.payload.projectId
          //Don't update if the projectData is no longer the current project
          if (projectId !== draft.selectedProjectId) return

          const projectData = action.payload.projectData
          draft.sites = projectData.sites || {}
          draft.zones = projectData.zones || {}
          draft.devices = projectData.devices || {}
          draft.deployments = projectData.deployments || {}
          draft.crowdCapacityReports = projectData.crowdCapacityReports || {}
          draft.trains = projectData.trains || {}
          draft.carriages = projectData.carriages || {}
          draft.counters = projectData.counters || {}
          draft.compounds = projectData.compounds || {}

          // draft.calibrations = projectData.calibrations || {}
          //#f0f Have a further look into this
          //1.Clear any deleted ones
          Object.keys(draft.calibrations).forEach(id => {
            if (!projectData.calibrations[id]) delete draft.calibrations[id]
          })
          Object.keys(projectData.calibrations || {}).forEach(id => {
            const calibration = projectData.calibrations[id]
            const deleting = calibration.deleting
            //Update the deleting field only
            //#f0f Removed for now, interfering with Calibration form logic
            // draft.calibrations[id] = { ...draft.calibrations[id], deleting }
          })

          draft.selectedProjectId = projectId
        }
        return
      case getType(tenantActionsAsync.createProject.success):
        {
          const project = action.payload.project
          const projectPermissions = action.payload.projectPermissions || []
          if (!project) return
          draft.projects[project.projectId] = project
          projectPermissions.forEach(p => {
            if (!p) return
            draft.projectPermissions[p.id] = p
          })
        }
        return
      case getType(tenantActionsAsync.cloneProject.success):
        {
          const project = action.payload.project
          const projectPermissions = action.payload.projectPermissions || []
          if (!project) return
          draft.projects[project.projectId] = project
          projectPermissions.forEach(p => {
            if (!p) return
            draft.projectPermissions[p.id] = p
          })
        }
        return
      case getType(tenantActionsAsync.updateProject.success):
        {
          const res = action.payload?.resObj?.project as IProject
          if (!res) return //Safety check
          const projectId = res.projectId
          draft.projects[projectId] = {
            ...draft.projects[projectId],
            ...res,
          }
        }
        return
      case getType(tenantActionsAsync.deleteProject.success):
        {
          const projectId = action.payload.projectId
          // const selected = draft.projects[projectId]
          const selected = draft.selectedProjectId
          //Change project selection to none if necessary
          if (selected && selected === projectId) {
            draft.selectedProjectId = ""
          }
          // delete draft.projects[projectId]
          //#0ff we mark for deletion instead
          const project = draft.projects[projectId]
          if (!project) return
          draft.projects[projectId] = { ...project, deleting: true }
        }
        return

      case getType(tenantActionsAsync.deleteDeployment.success):
        {
          const deploymentId = action.payload.id as string
          deleteDeployment(draft, deploymentId)
        }
        return
      // case getType(tenantActionsAsync.updateDeployment.success):
      //   {
      //     //#0f0 -> CHECK THIS, should have resObj
      //     //@ts-ignore
      //     const res = action.payload.deploymentRes as IDeployment
      //     const deploymentId = res.deploymentId
      //     draft.deployments[deploymentId] = {
      //       ...draft.deployments[deploymentId],
      //       ...res,
      //     }
      //   }
      //   return
      case getType(tenantActionsAsync.createSiteOverlay.success):
        {
          const res = action.payload.resObj
          if (!res) return //Safety check
          const siteId = res.siteId
          draft.sites[siteId] = res
        }
        return
      case getType(tenantActionsAsync.updateSite.success):
        {
          const res = action.payload.resObj
          if (!res) return //Safety check
          const siteId = res.siteId
          draft.sites[siteId] = {
            ...draft.sites[siteId],
            ...res,
          }
        }
        return
      case getType(tenantActionsAsync.updateSiteOverlay.success):
        {
          const res = action.payload.resObj
          if (!res) return //Safety check
          const siteId = res.siteId
          draft.sites[siteId] = {
            ...draft.sites[siteId],
            ...res,
          }
        }
        return
      case getType(tenantActionsAsync.deleteSite.success): {
        {
          const id = action.payload.id as string
          deleteSite(draft, id)
        }
        return
      }
      case getType(tenantActionsAsync.createZone.success):
        {
          const res = action.payload.resObj
          if (!res) return //Safety check
          const zoneId = res.zoneId
          draft.zones[zoneId] = res
        }
        return
      case getType(tenantActionsAsync.updateZone.success):
        {
          const res = action.payload.resObj
          if (!res) return //Safety check
          const zoneId = res.zoneId
          draft.zones[zoneId] = {
            ...draft.zones[zoneId],
            ...res,
          }
        }
        return
      case getType(tenantActionsAsync.deleteZone.success):
        {
          const id = action.payload.id
          deleteZone(draft, id)
        }
        return
      case getType(tenantActionsAsync.saveZoneConfig.success):
        {
          const zone = action.payload.zone
          const deployments = action.payload.deployments
          if (zone) draft.zones[zone.zoneId] = zone
          deployments.forEach(d => {
            draft.deployments[d.deploymentId] = d
          })
        }
        return
      case getType(tenantActionsAsync.createDeployment.success):
        {
          const res = action.payload.resObj as IDeployment
          if (!res) return //Safety check
          const deploymentId = res.deploymentId
          draft.deployments[deploymentId] = res
        }
        return
      case getType(tenantActionsAsync.updateDeployment.success):
        {
          const res = action.payload.resObj as IDeployment
          if (!res) return //Safety check
          const deploymentId = res.deploymentId
          draft.deployments[deploymentId] = {
            ...draft.deployments[deploymentId],
            ...res,
          }
        }
        return
      case getType(tenantActionsAsync.createDeploymentCombined.success):
        {
          const d = action.payload.newDeployment
          const deploymentId = d && d.deploymentId
          if (deploymentId) {
            draft.deployments[deploymentId] = d
          }
          const de = action.payload.newDevice
          const deviceId = de && de.deviceId
          if (deviceId) {
            draft.devices[deviceId] = de
          }
        }
        return
      case getType(tenantActionsAsync.updateDeploymentCombined.success):
        {
          const d = action.payload.newDeployment
          const deploymentId = d.deploymentId
          if (deploymentId) {
            draft.deployments[deploymentId] = {
              ...draft.deployments[deploymentId],
              ...d,
            }
          }
          const de = action.payload.newDevice
          const deviceId = de && de.deviceId
          if (deviceId) {
            draft.devices[deviceId] = {
              ...draft.devices[deviceId],
              ...de,
            }
          }
        }
        return
      case getType(tenantActionsAsync.deleteDeploymentCombined.success):
        {
          const { deviceId, deploymentId } = action.payload
          // if (deploymentId) delete draft.deployments[deploymentId]
          // if (deviceId) delete draft.devices[deviceId]

          //#0ff we mark for deletion instead
          //No need to do anything to device
          const deployment = draft.deployments[deploymentId]
          if (!deployment) return
          draft.deployments[deploymentId] = { ...deployment, deleting: true }
        }
        return
      case getType(tenantActionsAsync.deleteDeployment.success):
        {
          const id = action.payload.id as string
          deleteDeployment(draft, id)
        }
        return
      case getType(tenantActionsAsync.createCalibration.success):
        {
          const res = action.payload.resObj
          if (!res) return //Safety check
          const calibrationId = res.calibrationId
          draft.calibrations[`${calibrationId}`] = res
        }
        return
      case getType(tenantActionsAsync.updateCalibration.success):
        {
          const res = action.payload.resObj
          if (!res) return //Safety check
          const calibrationId = res.calibrationId
          draft.calibrations[calibrationId] = {
            ...draft.calibrations[calibrationId],
            ...res,
          }
        }
        return
      case getType(tenantActionsAsync.deleteCalibration.success):
        {
          const id = action.payload.id
          // delete draft.calibrations[calibrationId]
          //#0ff we mark for deletion instead
          const calibration = draft.calibrations[id]
          if (!calibration) return
          draft.calibrations[id] = { ...calibration, deleting: true }
        }
        return
      case getType(tenantActionsAsync.uploadCalibrationFloorplan.success):
        {
          const res = action.payload.resObj
          if (!res) return //Safety check
          const calibrationId = res.calibrationId
          draft.calibrations[`${calibrationId}`] = res
        }
        return
      case getType(tenantActionsAsync.uploadCalibrationSatellite.success):
        {
          const res = action.payload.resObj
          if (!res) return //Safety check
          const calibrationId = res.calibrationId
          draft.calibrations[`${calibrationId}`] = res
        }
        return
      case getType(tenantActionsAsync.uploadCalibrationGrid.success):
        {
          const res = action.payload.resObj
          if (!res) return //Safety check
          const calibrationId = res.calibrationId
          draft.calibrations[`${calibrationId}`] = res
        }
        return
      case getType(tenantActionsAsync.uploadCalibrationGridOnly.success):
        {
          const res = action.payload.resObj
          if (!res) return //Safety check
          const calibrationId = res.calibrationId
          draft.calibrations[`${calibrationId}`] = res
        }
        return
      case getType(tenantActionsAsync.startCalibration.success):
        {
          const res = action.payload.resObj
          if (!res) return //Safety check
          const calibrationId = res.calibrationId
          draft.calibrations[`${calibrationId}`] = res
        }
        return
      case getType(tenantActionsAsync.startMoodCalibration.success):
        {
          const res = action.payload.resObj
          if (!res) return //Safety check
          const calibrationId = res.calibrationId
          draft.calibrations[`${calibrationId}`] = res
        }
        return
      case getType(tenantActionsAsync.captureCalibrationImage.success):
        {
          const res = action.payload.resObj
          if (!res) return //Safety check
          const calibrationId = res.calibrationId
          draft.calibrations[`${calibrationId}`] = res
        }
        return
      case getType(tenantActionsAsync.uploadCalibrationImage.success):
        {
          const res = action.payload.resObj
          if (!res) return //Safety check
          const calibrationId = res.calibrationId
          draft.calibrations[`${calibrationId}`] = res
        }
        return
      case getType(tenantActionsAsync.captureMoodCalibrationImage.success):
        {
          const res = action.payload.resObj
          if (!res) return //Safety check
          const calibrationId = res.calibrationId
          draft.calibrations[`${calibrationId}`] = res
        }
        return
      case getType(tenantActionsAsync.createUser.success):
        {
          const user = action.payload.user as IUser
          const permission = action.payload
            .permission as UserOrgPermissionsAttribute
          if (!user) return //Safety check
          const user_id = user.user_id || ""
          draft.users[user_id] = user
          if (!permission) return
          draft.organisationPermissions[permission.id] = permission
          //If the user created/modified was an org admin this will be returned
          //#0f0 check this below
          // const projectPermissions = action.payload.projectPermissions as
          //   | { [id: string]: UserPermissionsAttribute }
          //   | undefined
          const projectPermissions = action.payload.organisationPermissions
          if (!projectPermissions) return
          draft.projectPermissions = projectPermissions
        }
        return
      case getType(tenantActionsAsync.inviteUser.success):
        {
          const user = action.payload.user as IUser
          const permission = action.payload
            .permission as UserOrgPermissionsAttribute
          if (!user) return //Safety check
          const user_id = user.user_id || ""
          draft.users[user_id] = user
          if (!permission) return
          draft.organisationPermissions[permission.id] = permission
          //If the user created/modified was an org admin this will be returned
          //#0f0 check this below
          // const projectPermissions = action.payload.projectPermissions as
          //   | { [id: string]: UserPermissionsAttribute }
          //   | undefined
          const projectPermissions = action.payload.organisationPermissions
          if (!projectPermissions) return
          draft.projectPermissions = projectPermissions
        }
        return
      case getType(tenantActionsAsync.updateUser.success):
        {
          const user = action.payload.user as IUser
          const permission = action.payload
            .permission as UserOrgPermissionsAttribute
          const userSettings = action.payload.userSettings as UserSettings
          if (!user) return //Safety check
          const user_id = user.user_id || ""
          draft.users[user_id] = user
          if (userSettings) draft.userSettings = userSettings
          if (!permission) return
          draft.organisationPermissions[permission.id] = permission
          //If the user created/modified was an org admin this will be returned
          //#0f0 check this below
          // const projectPermissions = action.payload.projectPermissions as
          //   | { [id: string]: UserPermissionsAttribute }
          //   | undefined
          const projectPermissions = action.payload.organisationPermissions
          if (!projectPermissions) return
          draft.projectPermissions = projectPermissions
        }
        return
      //This is from the user profile self edit page
      case getType(auth0ActionsAsync.profileEdit.success):
        {
          const user = action.payload.user as IUser
          if (!user) return
          const user_id = user.user_id || ""
          draft.users[user_id] = user
        }
        return
      case getType(tenantActionsAsync.deleteUser.success):
        {
          const userId = action.payload.userId
          delete draft.users[userId]
        }
        return
      case getType(tenantActionsAsync.updateProjectUser.success):
        {
          const { projectPermissions } = action.payload
          draft.projectPermissions = projectPermissions
        }
        return
      case getType(networkSetupActionsAsync.getIp.success):
        {
          const project = action.payload.project as IProject
          const deployments = action.payload.deployments as IDeployment[]
          ;(deployments || []).forEach(d => {
            draft.deployments[d.deploymentId] = d
          })
          if (!project) return
          draft.projects[project.projectId] = project
        }
        return
      case getType(tenantActionsAsync.getCameraSchedules.success):
        {
          const { schedules, recurrences } = action.payload
          let schedulesObj = {}
          schedules.forEach(s => schedulesObj[s.id] = s)
          let recurrencesObj = {}
          recurrences.forEach(r => recurrencesObj[r.id] = r)
          draft.schedules = schedulesObj
          draft.recurrences = recurrencesObj
        }
        return
      case getType(tenantActionsAsync.deleteCameraSchedule.success):
        {
          const { schedules, recurrences } = action.payload
          let schedulesObj = {}
          schedules.forEach(s => schedulesObj[s.id] = s)
          let recurrencesObj = {}
          recurrences.forEach(r => recurrencesObj[r.id] = r)
          draft.schedules = schedulesObj
          draft.recurrences = recurrencesObj
        }
        return
    }
  },
  initialTenantState
)

export default tenantReducer

//EPICS BELOW
export const projectUpdateEpic = createUpdateEpic(
  tenantActionsAsync.updateProject,
  "projects"
)
export const siteUpdateEpic = createUpdateEpic(
  tenantActionsAsync.updateSite,
  "sites"
) // Not used
export const zoneUpdateEpic = createUpdateEpic(
  tenantActionsAsync.updateZone,
  "zones"
)
export const deploymentUpdateEpic = createUpdateEpic(
  tenantActionsAsync.updateDeployment,
  "deployments"
)

export const calibrationsUpdateEpic = createUpdateEpic(
  tenantActionsAsync.updateCalibration,
  "calibrations"
)

export const siteDeleteEpic = createDeleteEpic(
  tenantActionsAsync.deleteSite,
  "sites"
)
export const zoneDeleteEpic = createDeleteEpic(
  tenantActionsAsync.deleteZone,
  "zones"
)
export const deploymentDeleteEpic = createDeleteEpic(
  tenantActionsAsync.deleteDeployment,
  "deployments"
)
export const calibrationsDeleteEpic = createDeleteEpic(
  tenantActionsAsync.deleteCalibration,
  "calibrations"
)

export const zoneCreateEpic = createCreateEpic(
  tenantActionsAsync.createZone,
  "zones"
)
export const deploymentCreateEpic = createCreateEpic(
  tenantActionsAsync.createDeployment,
  "deployments"
)
export const calibrationsCreateEpic = createCreateEpic(
  tenantActionsAsync.createCalibration,
  "calibrations"
)

/**
 * This Epic runs right after a login event occurs, will then
 * try to fetch for tenant profile
 */
export const tenantInitEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(auth0ActionsAsync.login.success)),
    switchMap(action => {
      const fetchUser$ = new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        async function fetchUser() {
          // console.log("ATTEMPTING TO FETCH USER...")
          const token = client ? await client.getTokenSilently() : ""
          const { apiGatewayUrl } = state.constants

          const userProfile = await dependencies.tenantAPI.getUserProfile({
            token,
            apiGatewayUrl,
          })
          if (!userProfile) throw new Error("could not fetch user profile")

          //Also grab users default projectId OR just the first one
          const defaultProjectId =
            userProfile.user && userProfile.user.defaultProjectId
          const firstProjectId = Object.keys(userProfile.projects || {})[0]
          const projectId = defaultProjectId || firstProjectId

          observer.next(
            tenantActionsAsync.init.success({ userProfile, projectId })
          )
          // if (!projectId) return

          // const projectData = await dependencies.tenantAPI.getProjectData({
          //   token,
          //   projectId,
          //   apiGatewayUrl,
          // })
          // observer.next(
          //   tenantActionsAsync.selectProject.success({ projectData, projectId })
          // )
        }
        fetchUser().catch(error => {
          observer.error(error)
        })
      })
        //Also retry on failure every 10s
        //Note TODO: this might fail indefinitely
        .pipe(retryWhen(errors => errors.pipe(delay(5000))))
      return fetchUser$
    }),
    catchError((error, caught) => {
      console.log(error)
      return caught.pipe(map(e => tenantActionsAsync.init.failure({ error })))
    })
  )
}

export const projectSelectEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.selectProject.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const zoneIds = Object.keys(state.tenant.zones)
        const { apiGatewayUrl } = state.constants
        const mode = state.app.mode
        async function projectSelect() {
          const token = client ? await client.getTokenSilently() : ""
          const projectId = action.payload.projectId
          const project = state.tenant.projects[projectId]
          if (!projectId) return

          const projectData = await dependencies.tenantAPI.getProjectData({
            token,
            apiGatewayUrl,
            projectId,
          })
          observer.next(
            tenantActionsAsync.selectProject.success({ projectData, projectId })
          )
          observer.next(
            dataV2ActionsAsync.initV2.request({ zoneIds })
          )
          // Set to density mode if selected project does not have train mode enabled
          if (!project?.trainMode && mode === "train") {
            observer.next(
              appActions.setMode("")
            )
          }
        }
        projectSelect().catch(error =>
          observer.next(tenantActionsAsync.selectProject.failure({ error }))
        )
      })
    })
  )
}

//This epic listens to data refresh action (every 15s)
//and then also re-updates the *project data* (Sites,Zones,Deployments,Calibrations)
export const projectRefreshEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(dataV2ActionsAsync.initV2.success)),
    switchMap(action => {
      return interval(DEFAULT_INTERVAL).pipe(
        switchMap(action => {
          return new Observable<RootAction>(observer => {
            const state = state$.value as IState
            const { tabActive } = state.app
            if (!tabActive) return

            const client = state.auth0.auth0Client
            const { apiGatewayUrl } = state.constants
            const { selectedProjectId } = state.tenant
            if (!selectedProjectId) return

            async function projectRefresh() {
              const token = client ? await client.getTokenSilently() : ""

              const projectData = await dependencies.tenantAPI.getProjectData({
                token,
                apiGatewayUrl,
                projectId: selectedProjectId,
              })
              observer.next(
                tenantActionsAsync.refreshProject.success({
                  projectData,
                  projectId: selectedProjectId,
                })
              )
            }
            projectRefresh().catch(error =>
              observer.next(
                tenantActionsAsync.refreshProject.failure({ error })
              )
            )
          })
        })
      )
    })
  )
}

//This epic listens to data refresh action (every 15s)
//and then also re-updates the users permissions, projects list
export const tenantRefreshEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(dataV2ActionsAsync.initV2.success)),
    switchMap(action => {
      return interval(DEFAULT_INTERVAL).pipe(
        switchMap(action => {
          return new Observable<RootAction>(observer => {
            const state = state$.value as IState
            const { tabActive } = state.app
            if (!tabActive) return

            const client = state.auth0.auth0Client
            const { apiGatewayUrl } = state.constants
            async function tenantRefresh() {
              const token = client ? await client.getTokenSilently() : ""
              const userProfile = await dependencies.tenantAPI.getUserProfile({
                token,
                apiGatewayUrl,
              })
              if (!userProfile) throw new Error("could not fetch user profile")

              observer.next(tenantActionsAsync.init.success({ userProfile }))
            }
            tenantRefresh().catch(error =>
              observer.next(tenantActionsAsync.init.failure({ error }))
            )
          })
        })
      )
    })
  )
}

export const projectCreateEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.createProject.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function projectCreate() {
          const token = client ? await client.getTokenSilently() : ""

          //1.Extract details from payload and state
          const { project } = action.payload
          const org = userOrganisationSelector(state)
          const organisationId = (org && org.organisationId) || ""

          //2.Construct project
          const projectObj: IProject = {
            projectId: v4(),
            projectName: project.projectName,
            description: project.description || "",
            measureOutdoor: project.measureOutdoor,
            deviceCount: project.deviceCount,
            start: project.start,
            end: project.end,
            organisationId,
            lat: project.lat || DEFAULT_CENTER[1],
            lng: project.lng || DEFAULT_CENTER[0],
            timezone: project.timezone,
          }

          //3.Create project
          const {
            project: newProject,
            projectPermissions,
          } = await dependencies.tenantAPI.createProject({
            token,
            apiGatewayUrl,
            organisationId,
            projectObj,
          })

          //3.Pass new values to reducer
          observer.next(
            tenantActionsAsync.createProject.success({
              project: newProject,
              projectPermissions,
            })
          )
        }
        projectCreate().catch(error =>
          observer.next(tenantActionsAsync.createProject.failure({ error }))
        )
      })
    })
  )
}

export const projectCloneEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.cloneProject.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        async function projectClone() {
          const token = client ? await client.getTokenSilently() : ""
          const { apiGatewayUrl } = state.constants

          //1.Extract details from payload and state
          const projectId = action.payload.projectId || ""
          const org = userOrganisationSelector(state)
          const organisationId = (org && org.organisationId) || ""

          //2.Clone project
          const {
            project,
            projectPermissions,
          } = await dependencies.tenantAPI.cloneProject({
            token,
            apiGatewayUrl,
            organisationId,
            projectId,
          })

          //3.Pass new values to reducer
          observer.next(
            tenantActionsAsync.cloneProject.success({
              project,
              projectPermissions,
            })
          )
        }
        projectClone().catch(error =>
          observer.next(tenantActionsAsync.cloneProject.failure({ error }))
        )
      })
    })
  )
}

export const projectDeleteEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.deleteProject.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function projectDelete() {
          const token = client ? await client.getTokenSilently() : ""

          //1.Extract details from payload and state
          const { projectId } = action.payload
          const org = userOrganisationSelector(state)
          const organisationId = (org && org.organisationId) || ""

          //2.Delete target project
          await dependencies.tenantAPI.deleteProject({
            apiGatewayUrl,
            token,
            projectId,
            organisationId,
          })

          //3.Pass new values to reducer
          observer.next(tenantActionsAsync.deleteProject.success({ projectId }))
        }
        projectDelete().catch(error =>
          observer.next(tenantActionsAsync.deleteProject.failure({ error }))
        )
      })
    })
  )
}

export const deploymentCombinedCreateEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.createDeploymentCombined.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function combinedCreate() {
          const token = client ? await client.getTokenSilently() : ""

          //1.Extract details from payload
          const { device, deployment } = action.payload

          //2.Create for both deployment and devices
          const newDevice = await dependencies.tenantAPI.createDevice({
            apiGatewayUrl,
            token,
            device,
          })
          const newDeployment = (await dependencies.tenantAPI
            .createDeployment({
              apiGatewayUrl,
              deployment,
              token,
            })
            .catch(error => {
              //If deployment create fails, attempt to delete previously created device
              try {
                if (!newDevice) return
                dependencies.tenantAPI.deleteDevice({
                  token,
                  apiGatewayUrl,
                  deviceId: newDevice.deviceId,
                })
              } catch (error) {} //Ignore
              throw new Error("Could not create deployment")
            })) as IDeployment

          //3.Pass new values to reducer
          observer.next(
            tenantActionsAsync.createDeploymentCombined.success({
              newDevice,
              newDeployment,
            })
          )
        }
        combinedCreate().catch(error =>
          observer.next(
            tenantActionsAsync.createDeploymentCombined.failure({ error })
          )
        )
      })
    })
  )
}
export const deploymentCombinedUpdateEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.updateDeploymentCombined.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function combinedUpdate() {
          const token = client ? await client.getTokenSilently() : ""

          //1.Extract details from payload
          const { device, deployment, deviceId, deploymentId } = action.payload
          const deployments = state.tenant.deployments
          const devices = state.tenant.devices
          const oldDeployment = deployments[deploymentId]
          const oldDevice = devices[deviceId]

          //2.Update details for both deployment and devices
          const deviceP = dependencies.tenantAPI.updateDevice({
            token,
            apiGatewayUrl,
            deviceId,
            device: {
              ...oldDevice,
              ...device,
            },
          })
          const deploymentP = dependencies.tenantAPI.updateDeployment({
            token,
            apiGatewayUrl,
            deploymentId,
            deployment: {
              ...oldDeployment,
              ...deployment,
              flagUpdateCalibrationDetail: true,
            },
          })
          const [newDevice, newDeployment] = await Promise.all([
            deviceP,
            deploymentP,
          ])

          //3.Pass new values to reducer
          observer.next(
            tenantActionsAsync.updateDeploymentCombined.success({
              newDevice,
              newDeployment,
            })
          )
        }
        combinedUpdate().catch(error =>
          observer.next(
            tenantActionsAsync.updateDeploymentCombined.failure({ error })
          )
        )
      })
    })
  )
}
export const deploymentCombinedDeleteEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.deleteDeploymentCombined.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        async function combinedDelete() {
          //1.Extract details from payload
          const { deviceId, deploymentId } = action.payload
          const { apiGatewayUrl } = state.constants

          //2.Update details for both deployment and devices
          // await deleteModel("devices")(state, {
          //   type: "",
          //   payload: { id: deviceId },
          // })
          //No need to delete devices - foreign key cascade handles it
          //If deployment is deleted via DELETE devices endpoint - an error occurs
          await deleteModel(apiGatewayUrl)("deployments")(state, {
            type: "",
            payload: { id: deploymentId },
          })

          //3.Pass new values to reducer
          observer.next(
            tenantActionsAsync.deleteDeploymentCombined.success({
              deviceId,
              deploymentId,
            })
          )
        }
        combinedDelete().catch(error =>
          observer.next(
            tenantActionsAsync.deleteDeploymentCombined.failure({ error })
          )
        )
      })
    })
  )
}

export const siteOverlayCreateEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.createSiteOverlay.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function overlayCreate() {
          const token = client ? await client.getTokenSilently() : ""

          //1. Get data from payload
          const {
            projectId,
            imageChanged,
            imageFile,
            id,
            body,
          } = action.payload

          //2.Create site
          let site = await dependencies.tenantAPI.createSite({
            apiGatewayUrl,
            token,
            site: body,
          })

          //3.If overlay added, create overlay
          if (imageChanged) {
            if (imageFile) {
              site = await dependencies.tenantAPI.uploadSiteOverlay({
                apiGatewayUrl,
                token,
                siteId: id,
                imageFile,
                projectId,
              })
            }
          }

          const resObj = site
          if (resObj && Object.keys(resObj).length > 0) {
            observer.next(
              tenantActionsAsync.createSiteOverlay.success({ resObj })
            )
          } else {
            throw new Error("site recieved is empty")
          }
        }
        overlayCreate().catch(error =>
          observer.next(tenantActionsAsync.createSiteOverlay.failure({ error }))
        )
      })
    })
  )
}
export const siteOverlayUpdateEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.updateSiteOverlay.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function overlayUpdate() {
          const token = client ? await client.getTokenSilently() : ""

          //If overlay has changed
          const {
            imageChanged,
            imageFile,
            id,
            projectId,
            body,
          } = action.payload
          const sites = state.tenant.sites
          let site = sites[id] || {}
          if (imageChanged) {
            if (imageFile) {
              site = await dependencies.tenantAPI.uploadSiteOverlay({
                apiGatewayUrl,
                token,
                siteId: id,
                projectId,
                imageFile,
              })
            } else {
              site = await dependencies.tenantAPI.deleteSiteOverlay({
                apiGatewayUrl,
                token,
                siteId: id,
              })
            }
          }

          const resObj = await dependencies.tenantAPI.updateSite({
            token,
            apiGatewayUrl,
            siteId: id,
            site: { ...site, ...body },
          })

          if (resObj && Object.keys(resObj).length > 0) {
            observer.next(
              tenantActionsAsync.updateSiteOverlay.success({ resObj })
            )
          } else {
            throw new Error("site recieved is empty")
          }
        }
        overlayUpdate().catch(error =>
          observer.next(tenantActionsAsync.updateSiteOverlay.failure({ error }))
        )
      })
    })
  )
}

export const calibrationFloorplanUploadEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.uploadCalibrationFloorplan.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function uploadFloorplan() {
          const token = client ? await client.getTokenSilently() : ""

          //1. Get data from payload
          const {
            projectId,
            calibrationId,
            deploymentId,
            image,
            floorplanorientation,
            metrepixelratio,
            lat,
            lng,
          } = action.payload

          const resObj = await dependencies.tenantAPI.uploadFloorplan({
            token,
            apiGatewayUrl,
            calibrationId,
            deploymentId,
            floorplanorientation,
            image,
            lat,
            lng,
            metrepixelratio,
            projectId,
          })

          if (resObj && Object.keys(resObj).length > 0) {
            observer.next(
              tenantActionsAsync.uploadCalibrationFloorplan.success({ resObj })
            )
          } else {
            throw new Error("calibration recieved is empty")
          }
        }
        uploadFloorplan().catch(error => {
          //Check for response && error field
          const message = error?.response?.data?.err
          const code = error?.response?.data?.err_code
          observer.next(
            tenantActionsAsync.uploadCalibrationFloorplan.failure({
              error,
              message,
              code,
            })
          )
        })
      })
    })
  )
}

export const calibrationCameraCaptureEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.captureCalibrationImage.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function cameraCapture() {
          const token = client ? await client.getTokenSilently() : ""

          //1. Get data from payload
          const {
            projectId,
            calibrationId,
            deploymentId,
            densityvieworientation,
          } = action.payload

          //2.set data
          const url = `${apiGatewayUrl}/calibrationHelpers/_capture`
          const data = {
            projectId,
            calibrationId,
            deploymentId,
            densityvieworientation,
          }

          const resObj = await dependencies.tenantAPI.captureCameraView({
            apiGatewayUrl,
            projectId,
            deploymentId,
            calibrationId,
            token,
            densityvieworientation,
          })

          if (resObj && Object.keys(resObj).length > 0) {
            observer.next(
              tenantActionsAsync.captureCalibrationImage.success({ resObj })
            )
          } else {
            throw new Error("calibration recieved is empty")
          }
        }
        cameraCapture().catch(error => {
          //Check for response && error field
          const message = error?.response?.data?.err
          const code = error?.response?.data?.err_code
          observer.next(
            tenantActionsAsync.captureCalibrationImage.failure({
              error,
              message,
              code,
            })
          )
        })
      })
    })
  )
}
export const calibrationCameraUploadEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.uploadCalibrationImage.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function cameraUpload() {
          const token = client ? await client.getTokenSilently() : ""

          const {
            projectId,
            calibrationId,
            deploymentId,
            imageFile,
            densityvieworientation
          } = action.payload

          const resObj = await dependencies.tenantAPI.uploadCameraView({
            apiGatewayUrl,
            token,
            deploymentId,
            calibrationId,
            imageFile,
            projectId,
            densityvieworientation
          })

          if (resObj && Object.keys(resObj).length > 0) {
            observer.next(
              tenantActionsAsync.uploadCalibrationImage.success({ resObj })
            )
          } else {
            throw new Error("calibration recieved is empty")
          }
        }
        cameraUpload().catch(error => {
          //Check for response && error field
          const message = error?.response?.data?.err
          const code = error?.response?.data?.err_code
          observer.next(
            tenantActionsAsync.uploadCalibrationImage.failure({
              error,
              message,
              code,
            })
          )
        })
      })
    })
  )
}
export const calibrationSatelliteUploadEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.uploadCalibrationSatellite.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function uploadFloorplan() {
          const token = client ? await client.getTokenSilently() : ""

          //1. Get data from payload
          const {
            projectId,
            calibrationId,
            deploymentId,
            provider,
            floorplanOrientation,
            zoom,
            lat,
            lng,
          } = action.payload

          const resObj = await dependencies.tenantAPI.uploadSatellite({
            token,
            apiGatewayUrl,
            projectId,
            calibrationId,
            deploymentId,
            floorplanorientation: floorplanOrientation,
            provider,
            zoom,
            lat,
            lng,
          })

          if (resObj && Object.keys(resObj).length > 0) {
            observer.next(
              tenantActionsAsync.uploadCalibrationSatellite.success({ resObj })
            )
          } else {
            throw new Error("calibration recieved is empty")
          }
        }
        uploadFloorplan().catch(error => {
          //Check for response && error field
          const message = error?.response?.data?.err
          const code = error?.response?.data?.err_code
          observer.next(
            tenantActionsAsync.uploadCalibrationSatellite.failure({
              error,
              message,
              code,
            })
          )
        })
      })
    })
  )
}

//Calculate metrepixel ratio for grid
const SQUARE_WIDTH = 1280
const getPixelRatio = (input: { gridCount: number; gridWidth: number }) => {
  const { gridCount, gridWidth } = input
  const gridPixels = SQUARE_WIDTH / (gridCount || 1)
  const meterPixelRatio = gridWidth / (gridPixels || 1)
  return meterPixelRatio
}
const getOrientation = (rotation: number) => {
  const b = rotation % 360 //Bearing
  if (b >= 45 && b < 135) return "east"
  if (b >= 135 && b < 225) return "south"
  if (b >= 225 && b < 315) return "west"
  return "north"
}

export const calibrationGridUploadEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.uploadCalibrationGrid.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function uploadGrid() {
          const token = client ? await client.getTokenSilently() : ""

          //1. Get data from payload
          const {
            projectId,
            calibrationId,
            deploymentId,
            lat,
            lng,
            gridWidth,
            gridCount,
            gridRotation,
            opacity,
          } = action.payload

          const resObj = await dependencies.tenantAPI.uploadGrid({
            token,
            apiGatewayUrl,
            projectId,
            calibrationId,
            deploymentId,
            floorplanorientation: getOrientation(+gridRotation),
            gridCount,
            gridWidth: +gridWidth,
            gridRotation,
            opacity,
            lat,
            lng,
          })
          if (resObj && Object.keys(resObj).length > 0) {
            observer.next(
              tenantActionsAsync.uploadCalibrationGrid.success({ resObj })
            )
          } else {
            throw new Error("calibration recieved is empty")
          }
        }
        uploadGrid().catch(error => {
          //Check for response && error field
          const message = error?.response?.data?.err
          const code = error?.response?.data?.err_code
          observer.next(
            tenantActionsAsync.uploadCalibrationGrid.failure({
              error,
              message,
              code,
            })
          )
        })
      })
    })
  )
}

export const calibrationGridOnlyUploadEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.uploadCalibrationGridOnly.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function uploadGridOnly() {
          const token = client ? await client.getTokenSilently() : ""

          //1. Get data from payload
          const {
            projectId,
            calibrationId,
            deploymentId,
            lat,
            lng,
            gridWidth,
            gridCount,
            gridRotation,
            // opacity,
          } = action.payload
          const metrepixelratio = getPixelRatio({
            gridWidth: +gridWidth,
            gridCount,
          })

          const resObj = await dependencies.tenantAPI.uploadGridOnly({
            token,
            apiGatewayUrl,
            projectId,
            calibrationId,
            deploymentId,
            floorplanorientation: getOrientation(+gridRotation),
            metrepixelratio,
            gridCount,
            gridRotation,
            lat,
            lng,
          })

          if (resObj && Object.keys(resObj).length > 0) {
            observer.next(
              tenantActionsAsync.uploadCalibrationGridOnly.success({ resObj })
            )
          } else {
            throw new Error("calibration recieved is empty")
          }
        }
        uploadGridOnly().catch(error => {
          //Check for response && error field
          const message = error?.response?.data?.err
          const code = error?.response?.data?.err_code
          observer.next(
            tenantActionsAsync.uploadCalibrationGridOnly.failure({
              error,
              message,
              code,
            })
          )
        })
      })
    })
  )
}

export const userCreateEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.createUser.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function usersCreate() {
          const token = client ? await client.getTokenSilently() : ""

          //1.Extract details from payload and state
          const user = action.payload.user as any
          const role = action.payload.role as string
          const org = userOrganisationSelector(state)
          const organisationId = (org && org.organisationId) || ""

          const {
            newUser,
            permission,
            organisationPermissions,
          } = await dependencies.tenantAPI.createUser({
            token,
            apiGatewayUrl,
            organisationId,
            role,
            user,
          })

          //3.Pass new values to reducer
          observer.next(
            tenantActionsAsync.createUser.success({
              user: newUser,
              userId: newUser.user_id || "",
              permission,
              organisationPermissions,
            })
          )
        }
        usersCreate().catch(error => {
          const e = (error.response && error.response.data) || {}
          const message = e.message || ""
          observer.next(
            tenantActionsAsync.createUser.failure({ error: e, message })
          )
        })
      })
    })
  )
}

export const userInviteEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.inviteUser.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function usersInvite() {
          const token = client ? await client.getTokenSilently() : ""

          //1.Extract details from payload and state
          const user = action.payload.user as any
          const role = action.payload.role as string
          const org = userOrganisationSelector(state)
          const organisationId = (org && org.organisationId) || ""

          const {
            newUser,
            permission,
            organisationPermissions,
          } = await dependencies.tenantAPI.inviteUser({
            token,
            apiGatewayUrl,
            organisationId,
            role,
            user,
          })

          //3.Pass new values to reducer
          observer.next(
            tenantActionsAsync.inviteUser.success({
              user: newUser,
              userId: newUser.user_id || "",
              permission,
              organisationPermissions,
            })
          )
        }
        usersInvite().catch(error => {
          const e = (error.response && error.response.data) || {}
          const message = e.message || ""
          observer.next(
            tenantActionsAsync.inviteUser.failure({ error: e, message })
          )
        })
      })
    })
  )
}

export const userUpdateEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.updateUser.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const pwExpired = state.app.forcePasswordUpdate
        const { apiGatewayUrl } = state.constants
        async function usersUpdate() {
          const token = client ? await client.getTokenSilently() : ""

          //1.Extract details from payload and state
          const { userId, user, role, userSettings } = action.payload
          const org = userOrganisationSelector(state)
          const organisationId = (org && org.organisationId) || ""

          const {
            newUser,
            permission,
            organisationPermissions,
            newUserSettings,
          } = await dependencies.tenantAPI.updateUser({
            token,
            apiGatewayUrl,
            organisationId,
            role,
            userId,
            user,
            userSettings,
          })

          // Log user out if their password was expired
          if (pwExpired) {
            observer.next(auth0ActionsAsync.logout.request({}))
            return
          }

          //3.Pass new values to reducer
          observer.next(
            tenantActionsAsync.updateUser.success({
              user: newUser,
              userId: newUser.user_id || "",
              permission,
              organisationPermissions,
              userSettings: newUserSettings,
            })
          )
        }
        usersUpdate().catch(error => {
          const e = (error.response && error.response.data) || {}
          const message = e.message || ""
          observer.next(
            tenantActionsAsync.updateUser.failure({ error: e, message })
          )
        })
      })
    })
  )
}

export const userDeleteEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.deleteUser.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function usersDelete() {
          const token = client ? await client.getTokenSilently() : ""

          //1.Extract details from payload and state
          const { userId } = action.payload
          const org = userOrganisationSelector(state)
          const organisationId = (org && org.organisationId) || ""

          await dependencies.tenantAPI.deleteUser({
            token,
            apiGatewayUrl,
            organisationId,
            userId,
          })

          //3.Pass new values to reducer
          observer.next(tenantActionsAsync.deleteUser.success({ userId }))
        }
        usersDelete().catch(error => {
          const e = (error.response && error.response.data) || {}
          const message = e.message || ""
          observer.next(
            tenantActionsAsync.deleteUser.failure({ error: e, message })
          )
        })
      })
    })
  )
}

export const projectUsersUpdateEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.updateProjectUser.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function syncProjectUsers() {
          const token = client ? await client.getTokenSilently() : ""

          //1.Extract details from payload and state
          const { projectId } = action.payload
          const initialPermissions = action.payload
            .initialPermissions as Permissions
          const permissions = action.payload.permissions as Permissions
          const org = userOrganisationSelector(state)
          const organisationId = (org && org.organisationId) || ""

          //2.Construct what to change
          //Calculate the difference between initialPermissions
          //and target permissions

          //1.To delete
          const toDelete: Permissions = {}
          Object.keys(initialPermissions).forEach(userId => {
            const role = initialPermissions[userId]
            if (!permissions[userId]) toDelete[userId] = role
          })

          //2.To create
          //3.To update
          const toCreate: Permissions = {}
          const toUpdate: Permissions = {}
          Object.keys(permissions).forEach(userId => {
            const role = permissions[userId]
            if (!initialPermissions[userId]) {
              toCreate[userId] = role
            } else {
              toUpdate[userId] = role
            }
          })

          const {
            projectPermissions,
          } = await dependencies.tenantAPI.syncProjectUsers({
            token,
            apiGatewayUrl,
            organisationId,
            projectId,
            toCreate,
            toUpdate,
            toDelete,
          })

          //3.Pass new values to reducer
          observer.next(
            tenantActionsAsync.updateProjectUser.success({ projectPermissions })
          )
        }
        syncProjectUsers().catch(error => {
          const e = (error.response && error.response.data) || {}
          const message = e.message || ""
          observer.next(
            tenantActionsAsync.updateProjectUser.failure({ error: e, message })
          )
        })
      })
    })
  )
}

export const calibrationStartEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.startCalibration.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function startCalibration() {
          const token = client ? await client.getTokenSilently() : ""

          //1. Get data from payload
          const {
            projectId,
            calibrationId,
            pointsMapView,
            pointsDensityView,
          } = action.payload

          const resObj = await dependencies.tenantAPI.startCalibration({
            token,
            apiGatewayUrl,
            projectId,
            calibrationId,
            pointsMapView,
            pointsDensityView,
          })

          if (resObj && Object.keys(resObj).length > 0) {
            observer.next(
              tenantActionsAsync.startCalibration.success({ resObj })
            )
          } else {
            throw new Error("calibration recieved is empty")
          }
        }
        startCalibration().catch(error => {
          //Check for response && error field
          const message = error?.response?.data?.err
          const code = error?.response?.data?.err_code
          observer.next(
            tenantActionsAsync.startCalibration.failure({
              error,
              message,
              code,
            })
          )
        })
      })
    })
  )
}

export const calibrationMoodStartEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.startMoodCalibration.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function startCalibration() {
          const token = client ? await client.getTokenSilently() : ""

          //1. Get data from payload
          const { projectId, calibrationId } = action.payload

          const resObj = await dependencies.tenantAPI.startMoodCalibration({
            token,
            apiGatewayUrl,
            projectId,
            calibrationId,
          })

          if (resObj && Object.keys(resObj).length > 0) {
            observer.next(
              tenantActionsAsync.startMoodCalibration.success({ resObj })
            )
          } else {
            throw new Error("calibration recieved is empty")
          }
        }
        startCalibration().catch(error => {
          //Check for response && error field
          const message = error?.response?.data?.err
          const code = error?.response?.data?.err_code
          observer.next(
            tenantActionsAsync.startMoodCalibration.failure({
              error,
              message,
              code,
            })
          )
        })
      })
    })
  )
}

export const deploymentVerifyEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.verifyDeployment.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function deploymentVerify() {
          const token = client ? await client.getTokenSilently() : ""

          //1.Extract details from payload
          const { projectId, deploymentId } = action.payload

          const resObj = await dependencies.tenantAPI.verifyDeployment({
            token,
            apiGatewayUrl,
            projectId,
            deploymentId,
          })
          if (!resObj) throw new Error("Could not update device")

          //3.Pass new values to reducer
          observer.next(
            tenantActionsAsync.verifyDeployment.success({
              deploymentVerification: "success",
            })
          )
        }
        deploymentVerify().catch(error => {
          //Check for response && error field
          const message = error?.response?.data?.err
          const code = error?.response?.data?.err_code
          observer.next(
            tenantActionsAsync.verifyDeployment.failure({
              error,
              message,
              code,
            })
          )
        })
      })
    })
  )
}

export const zoneConfigSaveEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.saveZoneConfig.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        async function zoneConfigSave() {
          const token = client ? await client.getTokenSilently() : ""

          //1.Extract details from payload
          const zoneId = action.payload.zoneId as string
          const zoneData = action.payload.zoneData as Partial<IZone>
          const deploymentConfigs = action.payload.deploymentConfigs as {
            [id: string]: DeploymentConfig
          }

          //2. Save each deployment config
          const dp = Object.keys(deploymentConfigs).map(async deploymentId => {
            const config = deploymentConfigs[deploymentId]
            const resObj = await dependencies.tenantAPI.saveDeploymentConfig({
              token,
              apiGatewayUrl,
              deploymentId,
              config,
            })

            if (resObj && Object.keys(resObj).length > 0) {
              return resObj
            } else {
              throw new Error(`deployment recieved is empty`)
            }
          })
          const deployments = await Promise.all(dp)

          //3. Finally save the zone config
          const zone = await dependencies.tenantAPI.saveZoneConfig({
            token,
            apiGatewayUrl,
            zoneId,
            zoneData,
          })

          if (zone && Object.keys(zone).length > 0) {
          } else {
            throw new Error(`zone recieved is empty`)
          }

          //4. Return new values to reducer
          observer.next(
            tenantActionsAsync.saveZoneConfig.success({ deployments, zone })
          )

          //5. Trigger data refresh
          observer.next(dataV2Actions.recalculate({ refreshData: true }))
          observer.next(dataV2Actions.recalculate({ refreshData: true }))
        }
        zoneConfigSave().catch(error =>
          observer.next(tenantActionsAsync.saveZoneConfig.failure({ error }))
        )
      })
    })
  )
}

export const getCameraSchedulesEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.getCameraSchedules.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        const { projectId, isEnabled, isDeleted } = action.payload
        async function getCameraSchedules() {
          const token = client ? await client.getTokenSilently() : ""

          const schedules = await dependencies.tenantAPI.getCameraSchedules({
            token,
            apiGatewayUrl,
            projectId,
            isEnabled,
            isDeleted,
          })

          const recurrencesPromises = schedules.schedules.map(schedule => {
            return dependencies.tenantAPI.getCameraScheduleRecurrence({
              token,
              apiGatewayUrl,
              scheduleId: schedule.id,
              isEnabled: true,
              isDeleted: false
            });
          });

          const recurrenceResponses = await Promise.all(recurrencesPromises);
          let recurrences = [];
          recurrenceResponses.forEach((r: any) => recurrences.push(...r.recurrences));

          observer.next(
            tenantActionsAsync.getCameraSchedules.success({ schedules: schedules.schedules, recurrences })
          )
        }
        getCameraSchedules().catch(error =>
          observer.next(tenantActionsAsync.getCameraSchedules.failure({ error }))
        )
      })
    })
  )
}

export const createCameraSchedulesEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.createCameraSchedule.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        const { schedule, recurrence } = action.payload
        async function createCameraSchedule() {
          const token = client ? await client.getTokenSilently() : ""

          const newSchedule = await dependencies.tenantAPI.createCameraSchedule({
            token,
            apiGatewayUrl,
            schedule: {
              project_id: schedule.projectId,
              start_date: schedule.startDate,
              end_date: schedule.endDate,
              start_time: schedule.startTime,
              end_time: schedule.endTime,
              is_enabled: schedule.isEnabled,
              is_deleted: schedule.isDeleted,
              configured_date: (new Date()).toISOString().replace('T', ' ').replace('Z', '+00'),
              configured_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }
          })

          if (newSchedule && newSchedule.schedule && recurrence) {
            const newRecurrence = await dependencies.tenantAPI.createCameraScheduleRecurrence({
              token,
              apiGatewayUrl,
              recurrence: {
                cs_id: newSchedule.schedule.id,
                type: recurrence.type,
                frequency: recurrence.frequency,
                is_enabled: recurrence.isEnabled,
                is_deleted: recurrence.isDeleted,
              }
            })
          }

          const startDate = toDate(schedule.startDate, schedule.startTime);
          const endDate = toDate(schedule.endDate, schedule.endTime);
          const updateString = recurrence?.frequency ?
            `${getLocaleDateString(startDate)} to ${getLocaleDateString(endDate)}, ${getLocaleTimeString(startDate)}-${getLocaleTimeString(endDate)}, recurring ${recurrence.frequency}.` :
            `${getLocaleDateString(startDate)} ${getLocaleTimeString(startDate)} to ${getLocaleDateString(endDate)} ${getLocaleTimeString(endDate)}.`
          observer.next(
            notificationActionsAsync.createNotification.request({
              message: `Camera schedule has been created by ${state.auth0.auth0User.email}. ${updateString}`
            })
          )
          observer.next(tenantActionsAsync.createCameraSchedule.success({}))
          observer.next(
            tenantActionsAsync.getCameraSchedules.request({ projectId: schedule.projectId, isEnabled: true, isDeleted: false })
          )
        }
        createCameraSchedule().catch(error =>
          observer.next(tenantActionsAsync.createCameraSchedule.failure({ error }))
        )
      })
    })
  )
}

export const updateCameraSchedulesEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.updateCameraSchedule.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        const { scheduleId, schedule, scheduleRecurrenceId, recurrence } = action.payload
        async function updateCameraSchedule() {
          const token = client ? await client.getTokenSilently() : ""

          const newSchedule = await dependencies.tenantAPI.updateCameraSchedule({
            token,
            apiGatewayUrl,
            scheduleId,
            schedule: {
              project_id: schedule.projectId,
              start_date: schedule.startDate,
              end_date: schedule.endDate,
              start_time: schedule.startTime,
              end_time: schedule.endTime,
              is_enabled: schedule.isEnabled,
              is_deleted: schedule.isDeleted,
              configured_date: (new Date()).toISOString().replace('T', ' ').replace('Z', '+00'),
              configured_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }
          })

          let newRecurrence: any;
          if (scheduleRecurrenceId && recurrence) {
            newRecurrence = await dependencies.tenantAPI.updateCameraScheduleRecurrence({
              token,
              apiGatewayUrl,
              scheduleRecurrenceId,
              recurrence: {
                cs_id: newSchedule.schedule.id,
                type: recurrence.type,
                frequency: recurrence.frequency,
                is_enabled: recurrence.isEnabled,
                is_deleted: recurrence.isDeleted,
              }
            })
          }

          const startDate = toDate(schedule.startDate, schedule.startTime);
          const endDate = toDate(schedule.endDate, schedule.endTime);
          const updateString = recurrence?.frequency ?
            `${getLocaleDateString(startDate)} to ${getLocaleDateString(endDate)}, ${getLocaleTimeString(startDate)}-${getLocaleTimeString(endDate)}, recurring ${recurrence.frequency}.` :
            `${startDate.toLocaleString()} to ${endDate.toLocaleString()}.`
          observer.next(
            notificationActionsAsync.createNotification.request({
              message: `Camera schedule (ID: ${scheduleId}) has been updated by ${state.auth0.auth0User.email}. ${updateString}`
            })
          )
          observer.next(tenantActionsAsync.updateCameraSchedule.success({}))
          observer.next(
            tenantActionsAsync.getCameraSchedules.request({ projectId: schedule.projectId, isEnabled: true, isDeleted: false })
          )
        }
        updateCameraSchedule().catch(error =>
          observer.next(tenantActionsAsync.updateCameraSchedule.failure({ error }))
        )
      })
    })
  )
}

export const deleteCameraSchedulesEpic: Epic<
  RootAction,
  RootAction,
  IState,
  Dependencies
> = (action$, state$, dependencies) => {
  return action$.pipe(
    filter(isActionOf(tenantActionsAsync.deleteCameraSchedule.request)),
    switchMap(action => {
      return new Observable<RootAction>(observer => {
        const state = state$.value as IState
        const client = state.auth0.auth0Client
        const { apiGatewayUrl } = state.constants
        const { projectId, scheduleId, scheduleRecurrenceId } = action.payload
        async function deleteCameraSchedule() {
          const token = client ? await client.getTokenSilently() : ""

          const newSchedule = await dependencies.tenantAPI.deleteCameraSchedule({
            token,
            apiGatewayUrl,
            scheduleId,
          })

          console.log(newSchedule);
          if (scheduleRecurrenceId) {
            const newRecurrence = await dependencies.tenantAPI.deleteCameraScheduleRecurrence({
              token,
              apiGatewayUrl,
              scheduleRecurrenceId,
            })
          }

          observer.next(
            notificationActionsAsync.createNotification.request({
              message: `Camera schedule (ID: ${scheduleId}) has been deleted by ${state.auth0.auth0User.email}.`
            })
          )
          observer.next(tenantActionsAsync.deleteCameraSchedule.success({}))
          observer.next(
            tenantActionsAsync.getCameraSchedules.request({ projectId, isEnabled: true, isDeleted: false })
          )
        }
        deleteCameraSchedule().catch(error =>
          observer.next(tenantActionsAsync.createCameraSchedule.failure({ error }))
        )
      })
    })
  )
}