import React from "react"
/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import styled from "@emotion/styled"
import { getType } from "typesafe-actions"
import { networkSetupActionsAsync } from "../../redux/reducers/networkSetupReducerV2"
import { auth0ActionsAsync } from "../../redux/reducers/auth0Reducer"
import { tenantActionsAsync } from "../../redux/reducers/tenantReducer"
import { projectActionsAsync } from "../../redux/reducers/projectReducer"
import { notificationActionsAsync } from "../../redux/reducers/notifications"
import { reportActionsAsync } from "../../redux/reducers/reportsReducer"
import { isReportDataEmpty } from "../../utils/isReportDataEmpty"



export const getToastByType = (id: string, type: string, payload: any = {}): {
    id: string,
    title: React.ReactNode
    color: any
    iconType?: any,
    toastLifeTimeMs?: number,
    text: React.ReactChild
} | null => {
    switch (type) {
        /**
         * Network setup actions
         */
        case getType(networkSetupActionsAsync.generate.failure):
            return {
                id,
                title: "Generating network failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later or contact DCM for help</p>
            }
        case getType(networkSetupActionsAsync.destroy.failure):
            return {
                id,
                title: "Destroying network failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later or contact DCM for help</p>
            }
        /**
         * Auth0 actions
         */
        case getType(auth0ActionsAsync.profileEdit.success):
            return {
                id,
                title: "Edit success",
                color: "success",
                // iconType: "alert",
                text: <p>User profile has been edited</p>
            }
        case getType(auth0ActionsAsync.profileEdit.failure):
            return {
                id,
                title: "Edit failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(auth0ActionsAsync.changePassword.success):
            return {
                id,
                title: "Email sent",
                color: "success",
                text: <p>A reset email has been successfully sent</p>
            }
        case getType(auth0ActionsAsync.changePassword.failure):
            return {
                id,
                title: "An error occurred",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        /**
         * Tenant messages
         * 
         */
        case getType(tenantActionsAsync.init.failure):
            return {
                id,
                title: "Connection to server timed out",
                color: "danger",
                iconType: "alert",
                text: <p>Could not establish connection to server. Please try again later</p>
            }
        case getType(tenantActionsAsync.selectProject.failure):
            return {
                id,
                title: "Project load failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.createProject.success):
            return {
                id,
                title: "Create success",
                color: "success",
                text: <p>Project has been created</p>
            }
        case getType(tenantActionsAsync.createProject.failure):
            return {
                id,
                title: "Create error",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.cloneProject.success):
            return {
                id,
                title: "Clone success",
                color: "success",
                text: <p>Project has been cloned</p>
            }
        case getType(tenantActionsAsync.cloneProject.failure):
            return {
                id,
                title: "Clone failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.deleteProject.success):
            return {
                id,
                title: "Delete request success",
                color: "success",
                text: <p>Project is now pending deletion</p>
            }
        case getType(tenantActionsAsync.deleteProject.failure):
            return {
                id,
                title: "Delete failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.updateProject.success):
            return {
                id,
                title: "Edit success",
                color: "success",
                text: <p>Project has been edited</p>
            }
        case getType(tenantActionsAsync.updateProject.failure):
            return {
                id,
                title: "Edit failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.createSiteOverlay.success):
            return {
                id,
                title: "Create success",
                color: "success",
                text: <p>Site has been created</p>
            }
        case getType(tenantActionsAsync.createSiteOverlay.failure):
            return {
                id,
                title: "Create failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.updateSite.success):
            return {
                id,
                title: "Updated success",
                color: "success",
                text: <p>Site has been edited</p>
            }
        case getType(tenantActionsAsync.updateSite.failure):
            return {
                id,
                title: "Update failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.updateSiteOverlay.success):
            return {
                id,
                title: "Updated success",
                color: "success",
                text: <p>Site has been edited</p>
            }
        case getType(tenantActionsAsync.updateSiteOverlay.failure):
            return {
                id,
                title: "Update failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.deleteSite.success):
            return {
                id,
                title: "Delete request success",
                color: "success",
                text: <p>Site is now pending deletion</p>
            }
        case getType(tenantActionsAsync.deleteSite.failure):
            return {
                id,
                title: "Delete failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.createZone.success):
            return {
                id,
                title: "Delete success",
                color: "success",
                text: <p>Zone has been created</p>
            }
        case getType(tenantActionsAsync.createZone.failure):
            return {
                id,
                title: "Create failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.updateZone.success):
            return {
                id,
                title: "Update success",
                color: "success",
                text: <p>Zone has been updated</p>
            }
        case getType(tenantActionsAsync.updateZone.failure):
            return {
                id,
                title: "Update failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.deleteZone.success):
            return {
                id,
                title: "Delete request success",
                color: "success",
                text: <p>Zone is now pending deletion</p>
            }
        case getType(tenantActionsAsync.deleteZone.failure):
            return {
                id,
                title: "Delete failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.saveZoneConfig.success):
            return {
                id,
                title: "Configuration save success",
                color: "success",
                text: <p>Zone configuration has successfully saved</p>
            }
        case getType(tenantActionsAsync.saveZoneConfig.failure):
            return {
                id,
                title: "Configuration save failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.createDeploymentCombined.success):
            return {
                id,
                title: "Create successful",
                color: "success",
                text: <p>Deployment has been created</p>
            }
        case getType(tenantActionsAsync.createDeploymentCombined.failure):
            return {
                id,
                title: "Create failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.updateDeploymentCombined.success):
            return {
                id,
                title: "Update successful",
                color: "success",
                text: <p>Deployment has been updated</p>
            }
        case getType(tenantActionsAsync.updateDeploymentCombined.failure):
            return {
                id,
                title: "Update failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.deleteDeploymentCombined.success):
            return {
                id,
                title: "Delete request success",
                color: "success",
                text: <p>Deployment is now pending deletion</p>
            }
        case getType(tenantActionsAsync.deleteDeploymentCombined.failure):
            return {
                id,
                title: "Delete failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.deleteDeployment.success):
            return {
                id,
                title: "Delete request success",
                color: "success",
                text: <p>Deployment is now pending deletion</p>
            }
        case getType(tenantActionsAsync.deleteDeployment.failure):
            return {
                id,
                title: "Delete failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.createCalibration.success):
            return {
                id,
                title: "Create successful",
                color: "success",
                text: <p>Calibration has been created</p>
            }
        case getType(tenantActionsAsync.createCalibration.failure):
            return {
                id,
                title: "Create failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.updateCalibration.success):
            return {
                id,
                title: "Update successful",
                color: "success",
                text: <p>Calibration has been updated</p>
            }
        case getType(tenantActionsAsync.updateCalibration.failure):
            return {
                id,
                title: "Update failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.deleteCalibration.success):
            return {
                id,
                title: "Delete request success",
                color: "success",
                text: <p>Calibration is now pending deletion</p>
            }
        case getType(tenantActionsAsync.deleteCalibration.failure):
            return {
                id,
                title: "Delete failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.uploadCalibrationFloorplan.failure):
            return {
                id,
                title: "Upload failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.uploadCalibrationSatellite.failure):
            return {
                id,
                title: "Upload failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.uploadCalibrationGrid.failure):
            return {
                id,
                title: "Upload failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.uploadCalibrationGridOnly.failure):
            return {
                id,
                title: "Upload failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.startCalibration.failure):
            return {
                id,
                title: "Could not calibrate",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.startMoodCalibration.success):
            return {
                id,
                title: "Detailed calibration success",
                color: "success",
                text: <p>Detailed calibration has successfully completed</p>
            }
        case getType(tenantActionsAsync.startMoodCalibration.failure):
            return {
                id,
                title: "Detailed calibration failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.captureCalibrationImage.failure):
            return {
                id,
                title: "Failed to capture image",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.captureMoodCalibrationImage.success):
            return {
                id,
                title: "Image captured",
                color: "success",
                text: <p>Image has been captured</p>
            }
        case getType(tenantActionsAsync.captureMoodCalibrationImage.failure):
            return {
                id,
                title: "Image captured failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.createUser.success):
            return {
                id,
                title: "Create success",
                color: "success",
                text: <p>Member has been successfully created</p>
            }
        case getType(tenantActionsAsync.createUser.failure):
            return {
                id,
                title: "Create error",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.updateUser.failure):
            return {
                id,
                title: "Update error",
                color: "danger",
                iconType: "alert",
                text: <p>{payload?.message}</p>
            }
        case getType(tenantActionsAsync.inviteUser.success):
            return {
                id,
                title: "Invite success",
                color: "success",
                text: <p>An invitation email has been sent</p>
            }
        case getType(tenantActionsAsync.inviteUser.failure):
            return {
                id,
                title: "Invite error",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.inviteUser.success):
            return {
                id,
                title: "Update success",
                color: "success",
                text: <p>Member updated successfully</p>
            }
        case getType(tenantActionsAsync.inviteUser.failure):
            return {
                id,
                title: "Update failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.deleteUser.success):
            return {
                id,
                title: "Delete success",
                color: "success",
                text: <p>Member has been deleted</p>
            }
        case getType(tenantActionsAsync.deleteUser.failure):
            return {
                id,
                title: "Delete failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(tenantActionsAsync.updateProjectUser.success):
            return {
                id,
                title: "Edit success",
                color: "success",
                text: <p>Project members have been edited</p>
            }
        case getType(tenantActionsAsync.updateProjectUser.failure):
            return {
                id,
                title: "Edit failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        /**
         * Project 
         */
        case getType(projectActionsAsync.createCameraSetup.failure):
            return {
                id,
                title: "Camera driver provisioning failed to start",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(projectActionsAsync.deleteCameraSetup.failure):
            return {
                id,
                title: "Camera driver deletion failed to start",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(projectActionsAsync.pingDevice.failure):
            return {
                id,
                title: "Connection failed",
                color: "danger",
                iconType: "alert",
                text: <p>Could not connect to device, please check the VPN network is correctly
                set up, or double check your RTSP link settings.</p>
            }
        case getType(notificationActionsAsync.createNotification.failure):
            return {
                id,
                title: "Add note failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(notificationActionsAsync.addNote.success):
            return {
                id,
                title: "Note added",
                color: "success",
                iconType: "alert",
                text: <p>Note added successfully</p>
            }
        //TODO: check to see if this works reliably 
        case getType(notificationActionsAsync.showNotification.success):
            return {
                id,
                title: "Notification",
                color: "success",
                iconType: "alert",
                text: <p>{payload?.notification?.message || ""}</p>
            }
        case getType(reportActionsAsync.getDayReport.success):
            if (!isReportDataEmpty(payload)) return null;
            return {
                id,
                title: "Get report failed",
                color: "danger",
                iconType: "alert",
                text: <p>No data found</p>
            }
        case getType(reportActionsAsync.getDayReport.failure):
            return {
                id,
                title: "Get report failed",
                color: "danger",
                iconType: "alert",
                text: <p>Please try again later</p>
            }
        case getType(notificationActionsAsync.subscribe.failure):
            return {
                id,
                title: "Add contact failed",
                color: "danger",
                iconType: "alert",
                text: <p>{payload?.error?.response?.data || "Please try again later"}</p>
            }
        case getType(tenantActionsAsync.createCameraSchedule.success):
            return {
                id,
                title: "Create schedule",
                color: "success",
                iconType: "alert",
                text: <p>Schedule added</p>
            }
        case getType(tenantActionsAsync.updateCameraSchedule.success):
            return {
                id,
                title: "Update schedule",
                color: "success",
                iconType: "alert",
                text: <p>Schedule updated</p>
            }
        case getType(tenantActionsAsync.deleteCameraSchedule.success):
            return {
                id,
                title: "Delete schedule",
                color: "success",
                iconType: "alert",
                text: <p>Schedule deleted</p>
            }


        /**
        * Default
        */
        default:
            return null;
    }
}