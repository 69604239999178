import axios from "axios"
import { IDeployment } from "../../@types/IDeployment"
import { IProject } from "../../@types/IProject"

/**
 * Helper functions for vpn setup API
 */
export const timeoutFn = (timeout: number) =>
  new Promise((_, reject) =>
    setTimeout(() => reject(new Error("timeout")), timeout)
  )

type Env = "dev" | "test" | "prod"
const TIMEOUT = 15000
const PING_TIMEOUT = 30000

export const networkAPIV2 = {
  /**
   * Gets the status of the vpn setup
   */
  getStatus: async (input: {
    environment: Env
    client_name: string
    token: string
    mgmtUrl: string
    vpnSetupPath: string
  }) => {
    if (typeof window === "undefined") return
    const { environment, client_name, token, mgmtUrl } = input
    const { vpnSetupPath } = input

    const res = (await Promise.race([
      fetch(`${mgmtUrl}/${vpnSetupPath}/v1/vpns/status`, {
        method: "POST",
        body: JSON.stringify({
          environment,
          client_name,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
      timeoutFn(TIMEOUT),
    ])) as Response
    //Throw error if error code
    if (res.status >= 400 && res.status < 600) {
      throw new Error("Bad response from server")
    }

    const json = await res.json()
    return json as {
      client_profile_status: string
      vpn_setup_status: string
    }
  },
  /**
   * Gets the ip address of the vpn setup
   */
  getIpAddress: async (input: {
    environment: Env
    client_name: string
    token: string
    mgmtUrl: string
    vpnSetupPath: string
  }) => {
    if (typeof window === "undefined") return
    const { environment, client_name, token, mgmtUrl } = input
    const { vpnSetupPath } = input

    const res = (await Promise.race([
      fetch(`${mgmtUrl}/${vpnSetupPath}/v1/vpns/ipaddress`, {
        method: "POST",
        body: JSON.stringify({
          environment,
          client_name,
          ip_type: "public", //#0f0 TEMP FIX
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
      timeoutFn(TIMEOUT),
    ])) as Response
    //Throw error if error code
    if (res.status >= 400 && res.status < 600) {
      throw new Error("Bad response from server")
    }

    const json = await res.json()
    return json as {
      ip: string
    }
  },
  /**
   * gets openvpn profile of the vpn server once ready
   */
  getProfile: async (input: {
    environment: Env
    client_name: string
    token: string
    mgmtUrl: string
    vpnSetupPath: string
  }) => {
    if (typeof window === "undefined") return
    const { environment, client_name, token, mgmtUrl } = input
    const { vpnSetupPath } = input

    const res = (await Promise.race([
      fetch(`${mgmtUrl}/${vpnSetupPath}/v1/vpns/profile`, {
        method: "POST",
        body: JSON.stringify({
          environment,
          client_name,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
      timeoutFn(TIMEOUT),
    ])) as Response

    //Throw error if error code
    if (res.status >= 400 && res.status < 600) {
      throw new Error("Bad response from server")
    }

    //TODO: check this later
    const text = await res.text()
    return text as string
  },
  /**
   * Tries to call a get to the IP address specified
   */
  pingServer: async (input: { ip: string }) => {
    if (typeof window === "undefined") return
    const { ip } = input

    try {
      await axios.get(`http://${ip}/`, { timeout: TIMEOUT })

      return "success"
    } catch (error) {
      return null
    }
  },
  /**
   * ping client through the vpn provisioning server
   */
  pingClient: async (input: {
    environment: Env
    client_name: string
    token: string
    client_ip_address: string
    mgmtUrl: string
    vpnSetupPath: string
  }) => {
    if (typeof window === "undefined") return
    const {
      environment,
      client_name,
      token,
      client_ip_address,
      mgmtUrl,
    } = input
    const { vpnSetupPath } = input

    try {
      await axios.post(
        `${mgmtUrl}/${vpnSetupPath}/v1/vpns/ping`,
        {
          environment,
          client_name,
          client_ip_address,
        },
        {
          timeout: TIMEOUT,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      return "success"
    } catch (error) {
      return null
    }
  },
  /**
   * Get client subnet CIDR of the network setup
   */
  getSubnetCidr: async (input: {
    environment: Env
    client_name: string
    token: string
    mgmtUrl: string
    vpnSetupPath: string
  }) => {
    if (typeof window === "undefined") return
    const {
      environment,
      client_name,
      token,
      mgmtUrl,
    } = input
    const { vpnSetupPath } = input

    try {
      const res = await axios.post(
        `${mgmtUrl}/${vpnSetupPath}/v1/vpns/connection-status`,
        { client_name, environment },
        {
          timeout: TIMEOUT,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      return res
    } catch (error) {
      return null
    }
  },
  /**
   * Generate a VPN server for the given client's project
   */
  generateServer: async (input: {
    environment: Env
    client_name: string
    client_cidr: string
    aws_region: string
    no_of_cameras: string
    token: string
    apiGatewayUrl: string
    organisationId: string
  }) => {
    if (typeof window === "undefined") return
    const { environment, client_name, token } = input
    const { client_cidr, aws_region, no_of_cameras } = input
    const { organisationId, apiGatewayUrl } = input

    const url = `${apiGatewayUrl}/vpnsetupHelpers/${organisationId}/${client_name}`
    const res = (await Promise.race([
      fetch(url, {
        method: "POST",
        body: JSON.stringify({
          environment,
          client_name,
          client_cidr,
          aws_region,
          no_of_cameras,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
      timeoutFn(TIMEOUT),
    ])) as Response

    //Throw error if error code
    if (res.status >= 400 && res.status < 600) {
      throw new Error("Bad response from server")
    }

    const json = await res.json()
    return json as {
      message: string
    }
  },
  /**
   * destroy the generated vpn server
   *
   */
  destroyServer: async (input: {
    environment: Env
    client_name: string
    token: string
    organisationId: string
    apiGatewayUrl: string
  }) => {
    if (typeof window === "undefined") return
    const { environment, client_name, token } = input
    const { organisationId, apiGatewayUrl } = input

    const url = `${apiGatewayUrl}/vpnsetupHelpers/${organisationId}/${client_name}`
    const res = (await Promise.race([
      fetch(url, {
        method: "DELETE",
        // body: JSON.stringify({
        //   environment,
        //   client_name,
        // }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
      timeoutFn(TIMEOUT),
    ])) as Response

    //Throw error if error code
    if (res.status >= 400 && res.status < 600) {
      throw new Error("Bad response from server")
    }

    //No content
    const text = await res.text()
    return text
  },
  storeRemoteHost: async (inputs: {
    token: string
    apiGatewayUrl: string
    organisationId: string
    projectId: string
    remoteHostIp: string
    remoteHostName: string
  }) => {
    const {
      token,
      apiGatewayUrl,
      organisationId,
      projectId,
      remoteHostIp,
      remoteHostName,
    } = inputs
    const url = `${apiGatewayUrl}/vpnsetupHelpers/${organisationId}/${projectId}/remoteHost`
    const remoteHostResponse = await axios.post(
      url,
      { remoteHostIp, remoteHostName },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    //Items that are updated
    const project = remoteHostResponse.data.project as IProject
    const deployments = remoteHostResponse.data.deployments as IDeployment[]

    return { project, deployments }
  },
}
